import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import Navbar from '../Common/Navbar'
import { useHistory } from 'react-router-dom'
import SingleFileUpload from '../FileUpload/singleFileUpload'
import moment from 'moment'
import Modal from 'react-modal';
import { decrypt } from '../Common/crypto'


export default function JobView() {

    const history = useHistory()
    //eslint-disable-next-line
    const [jobData, setJobData] = useState([])
    const [candidateView, setCandidateView] = useState(false)
    const [message, setMessage] = useState(null)
    const [payRate, setPayRate] = useState()
    const [immigrationStatus, setImmigrationStatus] = useState()
    const [experienceNeeded, setExperienceNeeded] = useState()
    const [loader, setLoader] = useState(false)
    const [jobEndDate, setJobEndDate] = useState()

    useEffect(() => {
        getJobDetail()
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('job-slug')])

    useEffect(() => {
        getPayRate()
        getImmigrationStatus()
        getExperienceNeeded()
        getJobEndDate()
    }, [jobData])

    const getJobDetail = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobDetail`, {
            method: 'POST',
            body: JSON.stringify({
                job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobData(result)
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoader(false)
            });
    }

    const getPayRate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getPayRates`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPayRate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getImmigrationStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getImmigrationStatus`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setImmigrationStatus(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getExperienceNeeded = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getExperienceNeeded`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setExperienceNeeded(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleCandidateView = () => {
        setCandidateView(true)
        setTimeout(() => {
            document.getElementById("attachResume").scrollIntoView({
                behavior: 'smooth',
            });
        }, 100)

    }
    const getJobEndDate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobEndDate`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobEndDate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div >
            {
                !new URLSearchParams(window.location.search)?.get('header-view') &&
                <Navbar />
            }
            <div className='container mb-5 ' style={{ marginTop: !new URLSearchParams(window.location.search)?.get('header-view') ? '7%' : '1%', fontSize: '12px' }}>
                <div>
                    <button style={{ cursor: 'pointer' }}
                        type="button"
                        onClick={() => {
                            history.push(`/careers?header-view=${new URLSearchParams(window.location.search)?.get('header-view')}&company-name=${new URLSearchParams(window.location.search)?.get('company-name')}`)
                        }}
                    >Back</button>
                </div>
                {
                    loader ?
                        <center>Loading....</center>
                        :
                        <>
                            {
                                jobData?.length === 0 ?
                                    <center>
                                        No Data Found
                                    </center>
                                    :
                                    <div id="job">
                                        {
                                            jobData?.map(x => {
                                                return (
                                                    <div className='row' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', fontSize: '14px' }}>
                                                        <div className='col-10'>
                                                            <div style={{ padding: 5 }}>
                                                                <div style={{ maxWidth: '85%' }}>
                                                                    <h4 style={{ color: x.is_hot === 1 ? 'red' : 'black' }}>{x.title} - {`NOVIJP00${x.joborder_id}`} </h4>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        x.city && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Location:</label> {x.city}  {x.state}  {x.country}</label>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        x?.duration && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Duration:</label> {x.duration}</label>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        x.start_date && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Start Date:</label> {x.start_date !== null ? moment(x.start_date).format('MM/DD/YYYY') : null}</label>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        jobEndDate?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Submission Deadline:</label> {jobEndDate?.value !== null ? jobEndDate?.value : null}</label>
                                                                    }
                                                                </div>

                                                                <div>
                                                                    {
                                                                        payRate?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Pay Rate:</label> {payRate?.value}</label>
                                                                    }

                                                                </div>
                                                                <div>
                                                                    {
                                                                        immigrationStatus?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Work Authorization:</label> {immigrationStatus?.value}</label>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        experienceNeeded?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Experience Needed:</label> {experienceNeeded?.value}</label>
                                                                    }
                                                                </div>
                                                                {/* <label style={{ color: 'grey', marginRight: '12px' }}>Openings {x.openings}</label> */}
                                                                <div dangerouslySetInnerHTML={{ __html: x.description }}>
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-2 col-sm-12'>
                                                            <button type="button" style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    // if (candidateView) {
                                                                    //     window.location.reload()
                                                                    // }
                                                                    handleCandidateView()
                                                                }}
                                                                className='btn btn-dark btn-sm mt-4'>Apply</button>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                        {
                                            message !== null &&
                                            <Modal
                                                isOpen={true}
                                                onRequestClose={() => window.location.replace('/Careers')}
                                                style={customStyles}

                                                shouldCloseOnOverlayClick={true}
                                            ><>
                                                    <div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px' }}>
                                                        {message}
                                                    </div>
                                                    <div>
                                                        <center>
                                                            {
                                                                new URLSearchParams(window.location.search)?.get('header-view') ?
                                                                    <button onClick={() => window.location.replace(`/careers?header-view=true&company-name=${new URLSearchParams(window.location.search)?.get('company-name')}`)} className='btn-sm btn btn-dark btn-sm'>Close</button>
                                                                    :
                                                                    <button onClick={() => window.location.replace('/careers')} className='btn-sm btn btn-dark btn-sm'>Close</button>
                                                            }
                                                        </center>
                                                    </div>
                                                </>
                                            </Modal>

                                        }
                                        <div id="attachResume" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', display: candidateView ? 'block' : 'none' }}>
                                            <label style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                                                CANDIDATE'S INFORMATION
                                            </label>
                                            <div className='container' style={{ fontSize: '14px' }}>
                                                <SingleFileUpload type="Attach Candidate" jobData={jobData[0]} setMessage={(data) => {
                                                    setCandidateView(false)
                                                    setMessage(data)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                            }
                        </>

                }
            </div>
            {
                !new URLSearchParams(window.location.search)?.get('header-view') &&
                <div style={{ marginLeft: '43%', paddingBottom: '10px', fontSize: '12px', position: 'absolute' }}>
                    ©{new Date().getFullYear()} Novisync Inc.All Rights Reserved
                </div>
            }

        </div >
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: '100%',
        // height: '80%'
    },
};