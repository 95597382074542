
import React, { useEffect, useRef, useState } from 'react'
import { OPEN_CATS_URL, RANKING_URL } from '../../config';
import moment from 'moment'
import Modal from 'react-modal';
import FileUpload from '../FileUpload';
import { getToken, removeToken, setToken } from '../../storage';
import LoadingOverlay from 'react-loading-overlay';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import $ from 'jquery'
import DataTable from 'react-data-table-component';
import FilterComponent from '../Common/FilterComponent';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import UpdateFileUpload from '../FileUpload/updateFileUpload';
import ContentEditable from 'react-contenteditable'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditCandidate from './editCandidate';
import { sendErrorEmailAlert } from '../Common/Alerts';


const Status = (props) => {
    const [count, setCount] = useState()

    useEffect(() => {
        getLatestStatus()
        //eslint-disable-next-line
    }, [])

    const getLatestStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getLatestStatus`, {
            method: 'POST',
            body: JSON.stringify({ candidateId: props.candidateId, joborderId: props.joborderId }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setCount(result.data[0].short_description)
                setToken('previousStatusId', result.data[0].candidate_joborder_status_id)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div>{count || "1.No Contact"}  </div>
    )
}


export default function ShowCandidates(props) {

    // const { SearchBar } = Search;
    const contentEditableRef = useRef()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [modalIsOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState()
    const [pipeLineInfo, setPipeLineInfo] = useState([])
    const [activityInfo, setActivityInfo] = useState([])
    const [candidateAdd, setCandidateAdd] = useState(false)
    const [candidateView, setCandidateView] = useState(false)
    const [jobPipeLineView, setJobPipeLineView] = useState(false)
    const [updatePipeLineData, setUpdatePipeLineData] = useState({})
    const [candidateData, setCandidateData] = useState([])
    const [activityData, setActivityData] = useState([])
    const [logPipelineData, setLogPipelineData] = useState({})
    const [pipeLineData, setPipeLineData] = useState({
        status: "yes",
        logActivity: "yes"
    })
    const [message, setMessage] = useState(null)
    const [editData, setEditData] = useState({
        view: false,
        keyValue: null,
        data: {}
    })
    const [rankView, setRankView] = useState(false)
    const [skills, setSkills] = useState(null)
    const [rankResult, setRankResult] = useState([])
    //eslint-disable-next-line
    const [rankFilterResult, setRankFilterResult] = useState([])
    //eslint-disable-next-line
    const [page, setPage] = useState(1)
    // const [paginationData, setPaginationData] = useState({})
    //eslint-disable-next-line
    const [sizePerPage, setSizePerPage] = useState(10)
    // const [filterData, setFilterData] = useState([])
    // const [searchFlag, setSearchFlag] = useState(false)
    const [resumeCount, setResumeCount] = useState(25)

    const [simpleSearch, setSimpleSearch] = useState(false)
    const [advanceSearch, setAdvanceSearch] = useState(false)
    //eslint-disable-next-line
    const [workAuthroization, setWorkAuthroization] = useState(null)
    //eslint-disable-next-line
    const [location, setLocation] = useState(null)
    const [advanceResultData, setAdvanceResultData] = useState([])
    const [advanceResultFilterData, setAdvanceResultFilterData] = useState([])
    const [skillOption, setSkillOption] = useState('Full Name')
    const [searchOption, setSearchOption] = useState({
        basicSearch: true,
        orSearch: false,
        andSearch: false,
        simpleRankSearch: true,
        rankLocationSearch: false
    })
    const [andWorkAuthroization, setAndWorkAuthroization] = useState(null)
    const [andLocation, setAndLocation] = useState(null)
    const [andSkills, setAndSkills] = useState(null)
    const [optionValue, setOptionValue] = useState(null)
    const [orWorkAuthroization, setOrWorkAuthroization] = useState(null)
    const [orLocation, setOrLocation] = useState(null)
    const [orSkills, setOrSkills] = useState(null)
    const [jobOrderModal, setJobOrderModal] = useState(false)
    const [selectedCandidateData, setSelectedCandidateData] = useState()
    const [jobOrderData, setJobOrderData] = useState([])
    const [jobOrderLoader, setJobOrderLoader] = useState(false)
    //eslint-disable-next-line
    const [jobOrderMessage, setJobOrderMessage] = useState("")
    const [closeModalOpen, setCloseModal] = useState(false)
    const [rankLocation, setRankLocation] = useState(null)
    const [jobTitle, setJobTitle] = useState(null)
    const [rankCountry, setRankCountry] = useState(null)
    const [rankState, setRankState] = useState(null)
    const [batchSearchData, setBatchSearchData] = useState([])
    //eslint-disable-next-line
    const [fileUrl, setFileUrl] = useState(null)
    const [isFileOpen, setIsFileOpen] = useState(false)
    const [attachNewFile, setAttachNewFile] = useState(false)
    //eslint-disable-next-line
    const [candidateId, setCandidateId] = useState()
    const [activityUpdateData, setActivityUpdateData] = useState()
    const [updateActivityModal, setUpdateActivityModal] = useState(false)
    //eslint-disable-next-line
    const [attachmentId, setAttachmentId] = useState(0)
    //eslint-disable-next-line
    const [viewType, setViewType] = useState('none')
    const [filterType, setFilterType] = useState("Active")
    const [addListView, setAddListView] = useState(false)
    const [listData, setListData] = useState([])
    const [newListView, setNewListView] = useState(false)
    const [listName, setListName] = useState(null)
    const [selectedData, setSelectedData] = useState(null)
    const [selectedListIds, setSelectedListIds] = useState()
    const [editListData, setEditListData] = useState(null)
    const [logonActivityView, setLogonActivityView] = useState(false)
    const [operationMode, setOperationMode] = useState(null)
    const [selectedRegarding, setSelectedRegarding] = useState('general');

    useEffect(() => {
        if (candidateView) {
            getAllCandidates()
        }
        //eslint-disable-next-line
    }, [candidateView])

    const getCandidatePipeLines = async (data) => {
        setPipeLineInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidatePipeLines`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPipeLineInfo(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getCandidateActivity = async (data) => {
        setActivityInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidateActivity`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setActivityInfo(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const getAllCandidates = async () => {

        setLoader(true)
        if (loader) {
            window.$('#exampleCandidate1').DataTable().clear().destroy();
        }
        if (getToken('candidateData') !== null) {
            setLoader(false)
            setData(JSON.parse(getToken('candidateData')))

        } else {
            await fetch(
                `${OPEN_CATS_URL}/eventRouter/getAllCandidates?id=${getToken('id')}`)
                .then((response) => response.json())
                .then((result) => {
                    setOperationMode(null)
                    setLoader(false)
                    setToken('candidateData', JSON.stringify(result.data))
                    setData(result.data)

                })
                .catch((error) => {
                    setLoader(false)
                    console.error('Error:', error);
                });
        }
        setLoader(false)
    }

    function closeModal() {
        setIsOpen(false);
    }
    function closePipeLineModal() {
        setJobPipeLineView(false)
        setUpdateActivityModal(false)
    }
    const openLogonActivityModal = (x) => {
        setLogonActivityView(true)
    }
    function closeLogonActivityModal() {
        setLogonActivityView(false)
    }
    function openModal(x) {
        // setUser(x)
        removeToken('candidateData')
        removeToken('candidateId')
        setToken('candidateId', x.result1.candidate_id)
        getSingleCandidate(x.result1.candidate_id)
        getCandidateActivity(x.result1)
        getCandidatePipeLines(x.result1)
        // setIsOpen(true)
    }

    function openSimpleSearchModal(x) {
        var data = {
            candidate_id: x.candidate_id
        }
        setOperationMode('simpleSearch')
        getSingleCandidate(x.candidate_id)
        getCandidateActivity(data)
        getCandidatePipeLines(data)
    }

    const getSingleCandidate = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getSingleCandidate`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: id }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setUser(result.data)
                setIsOpen(true)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const getData = (data) => {
        if (data) {
            return (<p dangerouslySetInnerHTML={{ __html: data.concat(" ") }} />)
        }
    }

    const handleView = (type) => {
        if (type === 'add') {
            setCandidateView(false)
            setCandidateAdd(true)
            setRankView(false)
        }
        if (type === 'view') {
            setCandidateView(true)
            setCandidateAdd(false)
            setRankView(false)

        }
        if (type === 'rankSearch') {
            setCandidateView(false)
            setCandidateAdd(false)
            setRankView(true)

        }
        if (type === 'simpleSearch') {
            setSearchOption({
                basicSearch: false,
                orSearch: false,
                andSearch: false,
                simpleRankSearch: true,
                rankLocationSearch: false,
            })
            setCandidateView(false)
            setCandidateAdd(false)
            setRankView(true)
            setSimpleSearch(true)
            setAdvanceSearch(false)
        }
        if (type === 'advanceSearch') {
            setSearchOption({
                basicSearch: true,
                orSearch: false,
                andSearch: false,
                simpleRankSearch: false,
                rankLocationSearch: false,
            })
            setCandidateView(false)
            setCandidateAdd(false)
            setRankView(true)
            setSimpleSearch(false)
            setAdvanceSearch(true)
        }
    }

    const handleUpdateJobOrder = (candidateId, joborderId, title, status) => {
        setJobPipeLineView(true)
        setUpdatePipeLineData({
            candidateId: candidateId,
            joborderId: joborderId,
            title: title
        })
        setPipeLineData({
            ...pipeLineData,
            // previousStatus: status
            candidateId: candidateId,
            joborderId: joborderId,
            title: title,
            created_id: getToken('id')
        })

    }

    const handleUpdateActivity = (candidate_id, activity_id, regarding, short_description, notes, type) => {
        setActivityUpdateData({
            activity_id: activity_id,
            candidate_id: candidate_id,
            regarding: regarding,
            short_description: short_description,
            notes: notes,
            type: type,
            entered_by: getToken('id')
        })
        setUpdateActivityModal(true)
    }
    //eslint-disable-next-line
    const handleNotes = (data) => {
        setActivityUpdateData({
            ...activityUpdateData,
            notes: data
        })

    }

    useEffect(() => {
        if (props.showCandidates) {
            getCandidateStatus()
            getActivityType()
        }
        //eslint-disable-next-line
    }, [jobPipeLineView, updateActivityModal, logonActivityView])

    const getCandidateStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobOrderStatus`)
            .then((response) => response.json())
            .then((result) => {
                setCandidateData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getActivityType = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getActivityType`)
            .then((response) => response.json())
            .then((result) => {
                setActivityData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handlePipeLine = (e) => {
        setPipeLineData({
            ...pipeLineData, [e.target.name]: e.target.value
        })
    }
    const handleActivityData = (e) => {
        setActivityUpdateData({
            ...activityUpdateData, [e.target.name]: e.target.value
        })
    }
    const handleLogonActivity = (e) => {
        const { name, value } = e.target;
        setLogPipelineData({
            ...logPipelineData,
            [name]: value,
        })
    }

    const handleLogSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        const previousStatus = getToken('previousStatusId');
        const created_id = getToken('id');


        if (selectedRegarding === 'general') {
            const activityData = {
                logActivity: "yes",
                activityType: logPipelineData?.activityType,
                activityNotes: logPipelineData?.activityNotes,
                created_id: created_id,
                joborderId: parseInt('-1'),
                candidateId: parseInt(getToken('candidateId'))
            };

            await fetch(`${OPEN_CATS_URL}/eventRouter/updateJobPipeLine`, {
                method: 'POST',
                body: JSON.stringify({ pipeLineData: activityData, previousStatus }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => response.json())
                .then((result) => {
                    closeLogonActivityModal()
                    openSimpleSearchModal({ candidate_id: parseInt(getToken('candidateId')) })
                    setLoader(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoader(false);
                });
        } else {
            const statusAndActivityData = {
                status: "yes",
                joborderId: parseInt(selectedRegarding),
                candidateId: parseInt(getToken('candidateId')),
                candidateStatus: parseInt(logPipelineData?.candidateStatus),
                logActivity: "yes",
                created_id: created_id,
                activityType: logPipelineData?.activityType,
                activityNotes: logPipelineData?.activityNotes,
            };

            await fetch(`${OPEN_CATS_URL}/eventRouter/updateJobPipeLine`, {
                method: 'POST',
                body: JSON.stringify({ pipeLineData: statusAndActivityData, previousStatus }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => response.json())
                .then((result) => {
                    closeLogonActivityModal()
                    openSimpleSearchModal({ candidate_id: parseInt(getToken('candidateId')) })
                    setLoader(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoader(false);

                });
        }


    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)

        var previousStatus = candidateData.find(o => o.short_description === document.getElementById(`previousStatus${pipeLineData.candidateId}`).innerText)?.candidate_joborder_status_id


        await fetch(
            `${OPEN_CATS_URL}/eventRouter/updateJobPipeLine`, {
            method: 'POST',
            body: JSON.stringify({ pipeLineData: pipeLineData, previousStatus: previousStatus }),

            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                getCandidateActivity(pipeLineData)
                getCandidatePipeLines(pipeLineData)
                toast(result.message)
                setJobPipeLineView(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }

    const handleActivitySubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/updateUserActivity`, {
            method: 'POST',
            body: JSON.stringify(activityUpdateData),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                getCandidateActivity(activityUpdateData)
                getCandidatePipeLines(activityUpdateData)
                toast(result.message)
                setUpdateActivityModal(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }


    const addToList = (data, keyValue) => {
        setAddListView(true)
        setSelectedData(data.result1.candidate_id)
        getAllList()
    }

    const addToListSearch = (data, keyValue) => {
        setAddListView(true)
        setSelectedData(data.candidate_id)
        getAllList()
    }

    const getAllList = async () => {
        setLoader(true)
        setListData([])
        if (loader) {
            window.$('#listTable').DataTable().clear().destroy();
        }
        await fetch(
            `${OPEN_CATS_URL}/listRouter/getAllList`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setListData(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleNewList = () => {
        setNewListView(true)
    }

    const handleAddList = async () => {
        if (listName !== null) {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/listRouter/createNewList`, {
                method: 'POST',
                body: JSON.stringify({
                    listName: listName,
                    data_item_type: 100,
                    userId: getToken('id')
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    setLoader(false)
                    setNewListView(false)
                    getAllList()

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    useEffect(() => {
        if (listData.length > 0) {
            $(document).ready(function () {

                var table = window.$('#listTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                })
                //eslint-disable-next-line
                table.columns().every(function () {
                    var that = this;
                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    })
                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
    }, [listData,])

    const handleListData = (status, index, listId) => {
        if (status) {
            setSelectedListIds({ ...selectedListIds, [index]: listId })
        } else {
            const selectedType = selectedListIds
            delete selectedType[index];
            setSelectedListIds(selectedType)
        }
    }

    const handleAddListData = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/addToList`, {
            method: 'POST',
            body: JSON.stringify({
                listIds: selectedListIds,
                candidateId: selectedData,
                data_item_type: 100,
                userId: getToken('id')
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setNewListView(false)
                getAllList()

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const handleUpdateList = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/updateListName`, {
            method: 'POST',
            body: JSON.stringify({
                editListData,
                userId: getToken('id')
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setNewListView(false)
                getAllList()
                setEditListData({ savedListID: 0 })

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    //eslint-disable-next-line
    const createSearchEdit = (data, keyValue) => {
        setEditData({
            view: true,
            keyValue: keyValue,
            data: data,

        })
    }

    //eslint-disable-next-line
    const handleUpdate = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/updateCandidate`, {
            method: 'POST',
            body: JSON.stringify(editData),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                // setMessage(result.message)
                setEditData({
                    view: false,
                    keyValue: null,
                    data: {}
                })
                removeToken('candidateData')
                getAllCandidates()
            })
            .catch((error) => {
                setLoader(true)
                console.error('Error:', error);
            });

    }
    //eslint-disable-next-line
    const handleSearchUpdate = async (type) => {

        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/updateSearchCandidate`, {
            method: 'POST',
            body: JSON.stringify(editData),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                handleAdvanceSearch()
                setLoader(false)
                if (type === 'advanceSearch') {
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.first_name = editData.data.first_name })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.last_name = editData.data.last_name })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.email1 = editData.data.email1 })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.phone_home = editData.data.phone_home })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.address = editData.data.address })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.city = editData.data.city })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.state = editData.data.state })
                    advanceResultData.filter(item => item.result1.candidate_id === editData.data.candidate_id).forEach(data => { data.result1.country = editData.data.country })
                }
                if (type === 'rankingApi') {
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.first_name = editData.data.first_name })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.email1 = editData.data.email1 })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.last_name = editData.data.last_name })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.phone_home = editData.data.phone_home })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.address = editData.data.address })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.city = editData.data.city })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.state = editData.data.state })
                    rankResult.filter(item => item.candidate_id === editData.data.candidate_id).forEach(data => { data.country = editData.data.country })

                }
                setEditData({
                    view: false,
                    keyValue: null,
                    data: {}
                })
            })
            .catch((error) => {
                setLoader(true)
                console.error('Error:', error);
            });

    }

    const handleEditChange = (e) => {
        setEditData({
            ...editData,
            data: {
                ...editData.data,
                [e.target.name]: e.target.value
            }
        })
    }

    //eslint-disable-next-line
    const handlePrimarySkill = (e) => {
        setEditData({
            ...editData,
            primary_skill: e.target.value
        })
    }
    //eslint-disable-next-line
    const handleImmigrationUpdate = (e) => {
        setEditData({
            ...editData,
            immigrationStatus: e.target.value
        })
    }



    const searchRank = () => {
        if (skills !== null) {
            setMessage("")
            setLoader(true)
            if (loader) {
                // window.$('#rankTable').DataTable().clear().destroy();
            }
            fetch(
                `${RANKING_URL}/spark_rank_api_v4`, {
                method: 'POST',
                body: JSON.stringify({
                    "title": jobTitle !== null && jobTitle !== undefined ? jobTitle : "NULL",
                    "country": rankCountry !== null && rankCountry !== undefined ? rankCountry : "NULL",
                    "state": rankState !== null && rankState !== undefined ? rankState : "NULL",
                    "city": rankLocation !== null && rankLocation !== undefined ? rankLocation : "NULL",
                    "skills": skills.split(","),
                    "top": resumeCount !== "all" ? parseInt(resumeCount) : resumeCount
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    removeToken('emailType')
                    setOperationMode(null)
                    setLoader(false)
                    setRankResult(result)
                    setRankFilterResult(result)
                    handleSaveSearch(skills, JSON.stringify(result))
                    if (!loader) {
                        // $(document).ready(function () {
                        //     window.$('#rankTable').DataTable({
                        //         "order": [[7, "asc"]],
                        //         mark: true,
                        //         destroy: true,
                        //     })
                        // })
                    }

                })
                .catch((error) => {
                    sendErrorEmailAlert('ranking', getToken('user'), error)
                    console.error('Error:', error);
                });
        } else {
            setMessage("Please Enter Skills")
        }
    }

    useEffect(() => {
        if (data?.length > 0) {
            $(document).ready(function () {


                $('#exampleCandidate1 tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });

                let table = window.$('#exampleCandidate1').DataTable({
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                    dom: 'Bfrtip',
                    buttons: [
                        'pageLength',
                        // {
                        //     extend: 'excelHtml5',
                        //     autoFilter: true,
                        //     sheetName: 'Exported data',
                        //     text: 'Export'
                        // }
                    ],

                })
                //eslint-disable-next-line
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });
                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
        //eslint-disable-next-line
    }, [data, new URLSearchParams(window.location.search).get('page')])


    const handleAdvanceSearch = async () => {
        setLoader(true)
        // if (loader) {
        setAdvanceResultData([])
        //     window.$('#advanceTable').DataTable().clear().destroy();
        // }
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/advanceSearch`, {
            method: 'POST',
            body: JSON.stringify({
                "location": location,
                "workAuthroization": workAuthroization,
                "option_value": optionValue,
                "cats_user": props.access,
                "skill_option": skillOption
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setOperationMode(null)
                setAdvanceResultData(result.data)
                setAdvanceResultFilterData(result.data)
                setLoader(false)
                // if (!loader) {
                //     $(document).ready(function () {
                //         window.$('#advanceTable').DataTable({
                //             "order": [[12, "asc"]],
                //             mark: true,
                //             destroy: true,
                //         })
                //     })
                // }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const handleOrSearch = async () => {
        setLoader(true)
        // if (loader) {
        setAdvanceResultData([])
        // window.$('#advanceTable').DataTable().clear().destroy();
        // }
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/orSearch`, {
            method: 'POST',
            body: JSON.stringify({
                "location": orLocation,
                "workAuthroization": orWorkAuthroization,
                "skills": orSkills,
                "cats_user": props.access
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setAdvanceResultData(result.data)
                // if (!loader) {
                //     $(document).ready(function () {
                //         window.$('#advanceTable').DataTable({
                //             "order": [[12, "asc"]],
                //             mark: true,
                //             destroy: true,
                //         })
                //     })
                // }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleAndSearch = async () => {
        setLoader(true)
        // if (loader) {
        setAdvanceResultData([])
        //     window.$('#advanceTable').DataTable().clear().destroy();
        // }
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/andSearch`, {
            method: 'POST',
            body: JSON.stringify({
                "location": andLocation,
                "workAuthroization": andWorkAuthroization,
                "skills": andSkills,
                "cats_user": props.access
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setAdvanceResultData(result.data)
                // if (!loader) {
                //     $(document).ready(function () {
                //         window.$('#advanceTable').DataTable({
                //             "order": [[12, "asc"]],
                //             mark: true,
                //             destroy: true,
                //         })
                //     })
                // }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleAdvanceSearchFilter = (e) => {
        if (e.target.value.length !== undefined) {
            setAdvanceResultData(advanceResultFilterData.filter((x) => {
                return x?.result1?.first_name?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.last_name?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.email1?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.phone_cell?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.phone_home?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.address?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.city?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.state?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.work_authorization?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result2?.map(y => {
                        return y?.value
                    }).toString()?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.skills?.map(y => {
                        return y
                    }).toString()?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.key_skills?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || x?.result1?.owner?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || moment(x?.result1?.date_created).format("MM-DD-YYYY, h:mm:ss").toString().toLowerCase().includes(e.target.value?.toLowerCase())

            }))
        } else {
            setAdvanceResultData(advanceResultFilterData)
        }
    }

    const handleCandidatePipeLine = (data) => {
        setSelectedCandidateData(data)
        setJobOrderModal(true)
        getAllJobs()
    }

    useEffect(() => {
        if (jobOrderData?.length > 0 && jobOrderLoader === false) {
            $(document).ready(function () {
                window.$('#jobOrderTable').DataTable(
                    {
                        "order": [[0, "desc"]],
                        bDestroy: true,
                        mark: true
                    }
                )
            })
        }
    }, [jobOrderData, jobOrderLoader])

    const getAllJobs = async () => {
        setJobOrderLoader(true)
        if (loader) {
            window.$('#jobOrderTable').DataTable().clear().destroy();
        }
        // if (getToken('joborderData') !== null) {
        //     setJobOrderLoader(false)
        //     setJobOrderData(JSON.parse(getToken('joborderData')))

        // } else {
        setJobOrderData([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getAllJobs?filterItem=joborderStatus&options=${filterType}&searchKey=`)
            .then((response) => response.json())
            .then((result) => {
                setJobOrderLoader(false)
                setJobOrderData(result.data)
                setToken('joborderData', JSON.stringify(result.data))

            })
            .catch((error) => {
                setJobOrderLoader(false)
                console.error('Error:', error);
            });
        // }
        setJobOrderLoader(false)

    }
    const createJobOrder = async (jobOrderId) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/jobOrderPipeLine`,
            {
                method: 'POST',
                body: JSON.stringify({ addedBy: getToken('id'), candidateId: selectedCandidateData.candidate_id, jobOrderId: jobOrderId }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobOrderModal(false)
                setCloseModal(true)
            })
            .catch((error) => {
                setLoader(false)
                props.getMessage('Failed to Add Candidate to Job Pipeline')
            });
    }

    useEffect(() => {
        if (simpleSearch) {
            getBatchSearchData()
        }
    }, [simpleSearch])

    useEffect(() => {
        if (jobOrderModal === true) {
            removeToken('joborderData')
            getAllJobs()
        }
        //eslint-disable-next-line
    }, [filterType])

    const getBatchSearchData = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getSearchBatchData`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setBatchSearchData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }

    const getBatchSearchDataValues = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getSearchBatchData?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setRankFilterResult(JSON.parse(result.data[0]?.data))
                setRankResult(JSON.parse(result.data[0]?.data))
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }

    const handleSaveSearch = async (searchString, data) => {
        // setLoader(true)
        setMessage(null)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/addSearchQuery`,
            {
                method: 'POST',
                body: JSON.stringify({
                    searchString: searchString,
                    data: data
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.text().then((text) => { throw Error(text) });
        })
            .then(() => {
                setLoader(false)
                getBatchSearchData()
            }).catch((error) => {
                // Handle the error
                console.log(JSON.parse(error.message).message)
            })
        setLoader(false)
    }

    const tryJSON = input => {
        try {
            return JSON.parse(input);
        } catch (e) {
            return false;
        }
    }

    //eslint-disable-next-line
    const parse = input => {
        let output = [];
        let chunks = input.split(/([{}])/);

        for (let i = 0; i < chunks.length; i++) {
            if (chunks[i] === '{') {
                // found some possible JSON; start at the last } and backtrack until it works.
                for (let j = chunks.lastIndexOf('}'); j > i; j--) {
                    if (chunks[j] === '}') {
                        // Does it blend?
                        let parsed = tryJSON(chunks.slice(i, j + 1).join(""))
                        if (parsed) {
                            // it does! Grab the whole thing and skip ahead
                            output.push(parsed);
                            i = j;
                        }
                    }
                }
            } else if (chunks[i]) {
                // neither JSON nor empty
                output.push(chunks[i])
            }
        }

        return output
    }
    //eslint-disable-next-line
    const handleSetBatchData = (search_key) => {
        let batchData = data.filter(x => x.search_key === search_key).map(y => {
            return y.data
        })


        var yourString = batchData[0]
        // console.log(JSON.parse(yourString))
        // var result2 = yourString?.slice(1, -1).replace(/ /g, '')
        // var result3 = result2?.split('},')

        // var data1 = result3.map(x => {
        //     return (x += "}")?.replace(/None/g, null)
        // })

        // var data2 = data1?.map(x => {
        //     return x?.replace(/'/g, '"')
        // })

        // var data3 = data2?.slice(0, -1)

        // var data4 = data3?.map((x, i) => {
        //     return parse(x)
        // })

        // var data5 = data4.map(x => {
        //     if (x.length === 1) {
        //         return x[0]
        //     }
        // })
        // setRankFilterResult(data5.filter(item => item !== undefined))
        // setRankResult(data5.filter(item => item !== undefined))

        setRankFilterResult(JSON.parse(yourString))
        setRankResult(JSON.parse(yourString))

    }
    //eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    //eslint-disable-next-line
    const [totalRows, setTotalRows] = useState(0);
    //eslint-disable-next-line
    const [perPage, setPerPage] = useState(10);

    // const fetchUsers = async page => {
    //     setLoading(true);
    //     const response = await axios.get(`${props.url}&page=${page}&per_page=${perPage}&delay=1`);
    //     setData(response.data.data);
    //     setTotalRows(response.data.pageCount);
    //     setLoading(false);
    // };

    // const handlePageChange = page => {
    //     fetchUsers(page);
    // };

    // const handlePerRowsChange = async (newPerPage, page) => {
    //     setLoading(true);
    //     const response = await axios.get(`${props.url}&page=${page}&per_page=${newPerPage}&delay=1`);
    //     setData(response.data.data);
    //     setPerPage(newPerPage);
    //     setLoading(false);
    // };

    // useEffect(() => {
    //     fetchUsers(1); // fetch page 1 of users
    // }, [props.url]);

    const columns = [
        {
            name: "Action",
            selector: (row, index) =>
                <label title="Add to List" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                    addToListSearch(row, index)
                }}>
                    <i class="bi bi-card-list"></i>
                </label>,
            sortable: true,
            width: '100px',

        },
        {
            name: "Pipeline",
            selector: row => <label title="Add to Joborder Pipeline" onClick={() => handleCandidatePipeLine(row)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Add</label>,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Priority',
            selector: (row, index) =>
                <>
                    {row?.priority}
                </>,
            sortable: true,
            width: '70px'
        },
        {
            name: "First Name",
            selector: (row, index) =>
                <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => openSimpleSearchModal(row)}>
                    {row?.first_name}
                </label>
            ,
            sortable: true,
            // width: '150px'
        },
        {
            name: "Last Name",
            selector: (row, index) =>
                <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => openSimpleSearchModal(row)}>
                    {row?.last_name}
                </label>
            ,
            sortable: true,
            // width: '120px'
        },
        {
            name: "Email",
            selector: (row, index) =>

                <>
                    {row?.email1}
                </>

            ,
            sortable: true,
        },

        {
            name: "Rank",
            selector: row => row?.rank,
            sortable: true,
            width: "80px"
        },
        {
            name: "Immigration Status",
            selector: row => row?.Immigration_Status !== 'None' ? row?.Immigration_Status : "",
            sortable: true,
            width: "100px"
        },
        {
            name: "Address",
            selector: (row, index) =>
                editData.view && index === editData.keyValue ?

                    <>
                        <input type="text" name="state" value={editData.data.state} onChange={(e) => handleEditChange(e)} />
                    </>
                    :
                    <>
                        {`${row?.country !== undefined && row?.country !== null ? row?.country : ""}`}   {`${row?.state !== null ? row?.state : ""}`} {`${row?.city !== null ? row?.city : ""}`}
                    </>
            ,
            sortable: true,
        },
        {
            name: "Skills",
            selector: row =>
                row?.technologys_present
            ,
            sortable: true,
        },
        {
            name: "Title",
            selector: row => row?.titles,
            sortable: true,
            width: "80px"
        },
        {
            name: "Owner",
            selector: row => row?.ower_name,
            sortable: true,
            width: "100px"
        },

        {
            name: "Created Time",
            selector: row => moment(row.date_created).format("MM-DD-YYYY, h:mm:ss"),
            sortable: true,
            width: "100px"
        }
    ]

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    const filteredItems = rankResult?.filter(
        item =>
            JSON.stringify(item)?.toLowerCase()?.indexOf(filterText.toLowerCase()) !== -1
    );


    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: totalRows,
    };

    const items = batchSearchData?.map(x => { return { name: x.search_key, id: x.id } })

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        setSkills(string.length === 0 ? null : string)

    }

    const handleOnHover = (result) => {
        // the item hovered
        console.log(result.name)

    }

    const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        getBatchSearchDataValues(item.id)
        // handleSetBatchData(item.name)
        setSkills(item.name.length === 0 ? null : item.name)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const refreshModal = () => {
        var iframe = document.getElementById('gview');
        //eslint-disable-next-line
        iframe.src = iframe.src;
    }


    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'search') {
            handleView('simpleSearch')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'add') {
            handleView('add')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'view') {
            handleView('view')
        }
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search).get('type')])


    return (
        props.showCandidates && (
            <>
                <ToastContainer />
                <div className="ml-4">
                    <div className="mb-1">
                        {
                            (candidateView || rankView) && loader && (
                                <div style={{ textAlign: 'center' }}>
                                    Loading...., Please Wait
                                </div>
                            )
                        }
                        {
                            <div>

                                {
                                    candidateAdd && (
                                        <FileUpload />
                                    )
                                }


                            </div>

                        }
                        <div>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={() => {
                                    if (operationMode === 'advanceSearch') {
                                        handleAdvanceSearch()
                                    }
                                    // uncomment to enable refresh after search
                                    // if (operationMode === 'simpleSearch') {
                                    //     searchRank()
                                    // }
                                    if (operationMode === 'viewCandidates') {
                                        getAllCandidates()
                                    }
                                    closeModal()
                                }}
                                style={customStyles}

                                shouldCloseOnOverlayClick={false}
                            >
                                <label><b>{`${user?.result1?.first_name}'s Information`}</b></label>
                                <button className="btn btn-sm btn-danger float-right mb-2" onClick={() => {
                                    if (operationMode === 'advanceSearch') {
                                        handleAdvanceSearch()
                                    }
                                    // if (operationMode === 'simpleSearch') {
                                    //     searchRank()
                                    // }
                                    if (operationMode === 'viewCandidates') {
                                        getAllCandidates()
                                    }
                                    closeModal()
                                }}>close</button>
                                <div>
                                    <EditCandidate candidate={user} access={props.access} />
                                </div>
                                <div>
                                    <label>Pipeline Information</label>
                                    {
                                        pipeLineInfo.length > 0 ?
                                            (
                                                <div className="">
                                                    <table className="table table-hover table-sm rwd-table " style={{ width: '100%' }}>
                                                        <thead>
                                                            <th>Title</th>
                                                            <th>Company</th>
                                                            <th>Owner</th>
                                                            <th>Added</th>
                                                            <th>Entered By</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                pipeLineInfo.map((x, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td data-th="Title" style={x.is_hot ? { color: 'red' } : { color: "" }} > {x.title}</td>
                                                                            <td data-th="Company">{x.companyName}</td>
                                                                            <td data-th="Owner">{x.first_name} {x.last_name}</td>
                                                                            <td data-th="Added">{moment(x.date_modified).format("MM-DD-YY hh:mm a")}</td>
                                                                            <td data-th="Entered By">{x.user_name}</td>
                                                                            <td data-th="Status" id={`previousStatus${x.candidate_id}`}>{<Status candidateId={x.candidate_id} joborderId={x.joborder_id} />}</td>
                                                                            <td data-th="Action"><button className="btn btn-sm btn-outline-primary" onClick={() => handleUpdateJobOrder(x.candidate_id, x.joborder_id, x.title, x.status)}>Update</button></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                            )
                                            :
                                            <div>No Pipeline Data Found</div>
                                    }

                                    <hr />
                                    <button type="button" className='btn btm-sm btn-outline-dark' onClick={() => openLogonActivityModal()}>Log Activity</button>
                                    <br />
                                    <label>Activity Information</label>
                                    {
                                        activityInfo.length > 0 ?
                                            <div className="rwd-table">
                                                <table className="table table-hover table-sm rwd-table" style={{ width: '100%' }}>
                                                    <thead>
                                                        <th>Date</th>
                                                        <th>Type</th>
                                                        <th>Entered</th>
                                                        <th>Regarding</th>
                                                        <th>Notes</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            activityInfo.map((x, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td data-th="Date">{moment(x.date_created).format("MM-DD-YY hh:mm a")}</td>
                                                                        <td data-th="Type">{x.short_description}</td>
                                                                        <td data-th="Entered">{x.user_first_name} {x.user_last_name}</td>
                                                                        <td data-th="Regarding">{x.regarding}</td>
                                                                        <td data-th="Notes">{getData(x.notes)}</td>
                                                                        <td data-th="Action"><button className="btn btn-sm btn-outline-primary" onClick={() => handleUpdateActivity(x.candidate_id, x.activity_id, x.regarding, x.short_description, x.notes, x.type)}>Update</button></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div>No Activity Data Found</div>
                                    }
                                </div>
                            </Modal>
                        </div>


                    </div>

                    <Modal
                        isOpen={jobPipeLineView}
                        onRequestClose={closePipeLineModal}
                        style={customStyles}

                        shouldCloseOnOverlayClick={false}
                    >
                        <label><b>{`${user?.result1?.first_name}'s Log Activity`}</b></label>
                        <button className="btn btn-danger float-right mb-2" onClick={closePipeLineModal}>close</button>
                        <div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <table className="table table-hover table-sm">
                                    <tr>
                                        <td>Regarding:</td>
                                        <td>{updatePipeLineData.title}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Status:</td>
                                        <td id="previousStatus">{<Status candidateId={updatePipeLineData.candidateId} joborderId={updatePipeLineData.joborderId} />}</td>

                                    </tr>
                                    {/* <tr>
                                        <td>Change Status:</td>
                                        <td className="col-6"><select name="status" onChange={(e) => handlePipeLine(e)} className="form-control" required>
                                            <option value="">Select One</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        </td>
                                    </tr> */}
                                    {/* {pipeLineData.status === "yes" && */}
                                    < tr >
                                        <td>Select Status:</td>
                                        <td className="col-6"><select name="candidateStatus" onChange={(e) => handlePipeLine(e)} className="form-control" required>
                                            <option value="">Select One</option>
                                            {
                                                candidateData.map((x, index) => {
                                                    return (
                                                        <option key={index} value={x.candidate_joborder_status_id}>{x.short_description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        </td>
                                    </tr>
                                    {/* } */}
                                    {/* <tr>
                                        <td>Log an Activity:</td>
                                        <td className="col-6"><select name="logActivity" onChange={(e) => handlePipeLine(e)} className="form-control" required>
                                            <option value="">Select One</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        </td>
                                    </tr> */}
                                    {/* {pipeLineData.logActivity === "yes" && */}
                                    <>
                                        < tr >
                                            <td>Activity Type:</td>
                                            <td className="col-6"><select name="activityType" onChange={(e) => handlePipeLine(e)} className="form-control" required>
                                                <option value="">Select One</option>
                                                {
                                                    activityData.map((x, index) => {
                                                        return (
                                                            <option key={index} value={x.activity_type_id}>{x.short_description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            </td>
                                        </tr>
                                        < tr >
                                            <td>Activity Notes:</td>
                                            <td className="col-6">
                                                <textarea className="form-control" name="activityNotes" onChange={(e) => handlePipeLine(e)} required></textarea>
                                            </td>
                                        </tr>
                                    </>
                                    {/* } */}
                                    <tr>
                                        <td>{
                                            message === null ?
                                                <>
                                                    <button type="submit" className="btn btn-sm btn-outline-primary mr-2">{loader ? "Loading..." : "Save"}</button>
                                                    <button type="button" onClick={closePipeLineModal} className="btn btn-sm btn-outline-danger">Cancel</button>

                                                </>
                                                :
                                                <lable style={{ color: 'green' }}>{message}</lable>
                                        }
                                        </td>
                                    </tr>
                                </table>
                            </form>

                        </div>
                    </Modal>
                    <Modal
                        isOpen={updateActivityModal}
                        onRequestClose={closePipeLineModal}
                        style={customStyles}

                        shouldCloseOnOverlayClick={false}
                    >
                        <label><b>{`${user?.result1?.first_name}'s Log Activity`}</b></label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => closePipeLineModal()}>close</button>
                        <div>
                            <form onSubmit={(e) => handleActivitySubmit(e)}>
                                <table className="table table-hover table-sm">
                                    <tr>
                                        <td>Regarding:</td>
                                        <td>{activityUpdateData?.regarding}</td>
                                    </tr>
                                    <tr>
                                        <td>Activity Type:</td>
                                        <td className="col-6">

                                            <select name="activityType" onChange={(e) => handleActivityData(e)} className="form-control" required>
                                                <option value={activityUpdateData?.type}>{activityUpdateData?.short_description}</option>
                                                {
                                                    activityData.map((x, index) => {
                                                        return (
                                                            <option key={index} value={x.activity_type_id}>{x.short_description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    < tr >
                                        <td>Activity Notes:</td>
                                        <td className="col-6">
                                            <ContentEditable
                                                style={{ height: 'auto' }}
                                                className='form-control'
                                                innerRef={contentEditableRef}
                                                html={activityUpdateData?.notes} // innerHTML of the editable div
                                                disabled={false}       // use true to disable editing
                                                onChange={(e) => {
                                                    setActivityUpdateData({
                                                        ...activityUpdateData,
                                                        notes: e.target.value
                                                    })
                                                }} // handle innerHTML change
                                                tagName='Notes' // Use a custom HTML tag (uses a div by default)
                                            />
                                            {/* <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: activityUpdateData?.notes }} className="form-control" style={{ height: 'auto' }} contentEditable={true}
                                                onInput={e => {
                                                    handleNotes(e.currentTarget.textContent)
                                                }}
                                                suppressContentEditableWarning={true}
                                            >
                                            </div> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{
                                            message === null ?
                                                <>
                                                    <button type="submit" className="btn btn-sm btn-outline-primary mr-2">{loader ? "Loading..." : "Save"}</button>
                                                    <button type="button" onClick={() => closePipeLineModal()} className="btn btn-sm btn-outline-danger">Cancel</button>

                                                </>
                                                :
                                                <lable style={{ color: 'green' }}>{message}</lable>
                                        }
                                        </td>
                                    </tr>
                                </table>
                            </form>

                        </div>
                    </Modal>
                    <Modal
                        isOpen={logonActivityView}
                        onRequestClose={closeLogonActivityModal}
                        style={customStyles}

                        shouldCloseOnOverlayClick={false}
                    >
                        <label><b>{`${user?.result1?.first_name}'s Log Activity`}</b></label>
                        <button className="btn btn-danger float-right mb-2" onClick={closeLogonActivityModal}>close</button>
                        <div>
                            <form onSubmit={(e) => handleLogSubmit(e)}>
                                <table className="table table-hover table-sm">
                                    <tr>
                                        <td>Regarding</td>
                                        <td className="col-6">
                                            <select
                                                name="regarding"
                                                onChange={(e) => {
                                                    handleLogonActivity(e);
                                                    setSelectedRegarding(e.target.value);
                                                }}
                                                className="form-control"
                                                required
                                            >
                                                <option value="">Select One</option>
                                                <option value="general">General</option>
                                                {pipeLineInfo.map(x => (
                                                    <option key={x.joborder_id} value={x.joborder_id}>
                                                        {`NOVIJP00${x.candidate_joborder_id}-${x.title}`}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    {
                                        selectedRegarding !== 'general' &&
                                        <>
                                            <tr>
                                                <td>Current Status:</td>
                                                <td key={selectedRegarding} id="previousStatus">{<Status candidateId={parseInt(getToken('candidateId'))} joborderId={parseInt(selectedRegarding)} />}</td>

                                            </tr>
                                            <tr>
                                                <td>Status:</td>
                                                <td className="col-6">
                                                    <select
                                                        name="candidateStatus"
                                                        onChange={(e) => handleLogonActivity(e)}
                                                        className="form-control"
                                                        required
                                                    >
                                                        <option value="">Select One</option>
                                                        {candidateData.map((x, index) => (
                                                            <option key={index} value={x.candidate_joborder_status_id}>
                                                                {x.short_description}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    <tr>
                                        <td>Activity Type:</td>
                                        <td className="col-6">
                                            <select
                                                name="activityType"
                                                onChange={(e) => handleLogonActivity(e)}
                                                className="form-control"
                                                required
                                            >
                                                <option value="">Select One</option>
                                                {activityData.map((x, index) => (
                                                    <option key={index} value={x.activity_type_id}>
                                                        {x.short_description}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    < tr >
                                        <td>Activity Notes:</td>
                                        <td className="col-6">
                                            <textarea className="form-control" name="activityNotes" onChange={(e) => handleLogonActivity(e)} required></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{
                                            message === null ?
                                                <>
                                                    <button type="submit" className="btn btn-sm btn-outline-primary mr-2">{loader ? "Loading..." : "Save"}</button>
                                                    <button type="button" onClick={closeLogonActivityModal} className="btn btn-sm btn-outline-danger">Cancel</button>

                                                </>
                                                :
                                                <label style={{ color: 'green' }}>{message}</label>
                                        }
                                        </td>
                                    </tr>
                                </table>
                            </form>

                        </div>
                    </Modal>
                </div >

                <div style={{ width: '90%', margin: 'auto', marginBottom: '10px' }}>
                    {
                        candidateView && (
                            <LoadingOverlay
                                active={loader}
                                spinner
                                text=''
                            >
                                <div className="row justify-content-center" style={{ width: '100%', margin: 'auto', overflow: 'auto', fontSize: '13px' }}>
                                    <div className="col-auto">
                                        <div>
                                            <label>Hide/Unhide Columns</label>
                                            <div style={{ fontSize: '10px' }}>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Action</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>Pipeline</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Priority</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>First Name</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Last Name</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>Email</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>Phone</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="8" /> </label><label className='m-1'>Immigration Status</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="9" /> </label><label className='m-1'>Desired Pay</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="10" /></label><label className='m-1'> Address</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="11" /></label><label className='m-1'> Primary Skills</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="12" /></label><label className='m-1'> Owner</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="13" /></label><label className='m-1'> Modified</label></label>
                                            </div>
                                            <br />
                                            <table id="exampleCandidate1" className="table table-hover table-sm rwd-table" style={{ width: '100%', overflow: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ display: 'none' }}>Candidate Id</th>
                                                        <th>Action</th>
                                                        <th>Pipeline</th>
                                                        <th>Priority</th>
                                                        <th>FirstName</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Immigration Status</th>
                                                        <th>Desired Pay</th>
                                                        <th>Address</th>
                                                        <th>Primary Skills</th>
                                                        <th>Owner</th>
                                                        <th>Modified</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        data?.map((x, index) => {
                                                            return (

                                                                <tr key={index}
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden'
                                                                    }}
                                                                >
                                                                    <td data-th="Candidate Id" style={{ display: 'none' }}>{x.result1.candidate_id}</td>
                                                                    <td data-th="Action">
                                                                        {
                                                                            <label title="Add to List" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                                addToList(x, index)
                                                                            }}>
                                                                                <i class="bi bi-card-list"></i>
                                                                            </label>
                                                                        }
                                                                    </td>

                                                                    <td data-th="Pipeline" title="Add to Joborder Pipeline" onClick={() => handleCandidatePipeLine(x?.result1)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Add</td>
                                                                    <td data-th="Priority">
                                                                        {x.result1.priority}
                                                                    </td>
                                                                    <td data-th="FirstName">
                                                                        <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                            openModal(x)
                                                                            setOperationMode('viewCandidates')
                                                                        }}>{x.result1.first_name}</label>
                                                                    </td>
                                                                    <td data-th="Last Name">
                                                                        <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                            openModal(x)
                                                                            setOperationMode('viewCandidates')
                                                                        }}>{x.result1.last_name}</label>
                                                                    </td>
                                                                    <td data-th="Email" style={{ wordWrap: 'break-word', }}>
                                                                        {x?.result1?.email1}
                                                                    </td>
                                                                    <td data-th="Phone" style={{ wordWrap: 'break-word', }}>
                                                                        {x?.result1?.phone_home}
                                                                    </td>
                                                                    <td data-th="Immigration Status">
                                                                        {x?.result1?.immigration_status}
                                                                    </td>
                                                                    <td data-th="Desired Pay">
                                                                        {x.result1?.desired_pay}
                                                                    </td>
                                                                    <td data-th="Address">
                                                                        {x.result1?.country} {x.result1?.state} {x.result1?.city}

                                                                    </td>
                                                                    <td data-th="Primary Skills">
                                                                        {x.result1.primary_skills}
                                                                    </td>
                                                                    <td data-th="Owner">
                                                                        {x.result1.owner}
                                                                    </td>
                                                                    <td data-th="Modified">{moment(x.result1.date_created).format("MM-DD-YYYY, h:mm:ss")}</td>


                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </LoadingOverlay>

                        )
                    }
                </div>

                <div className='ml-4' style={{ margin: 'auto', marginBottom: '10px' }}>
                    {
                        rankView && (
                            <>
                                <div className="mt-2 mb-2">
                                    <button type="button" className={simpleSearch ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm  mr-2"} onClick={() => handleView('simpleSearch')}>Simple Search</button>
                                    <button type="button" className={advanceSearch ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm  mr-2"} onClick={() => handleView('advanceSearch')}>Advance Search</button>
                                </div>
                                {
                                    simpleSearch &&
                                    <>
                                        <div className="">
                                            <div className='col-12 row'>
                                                <div className='col-lg-5 col-sm-12'>
                                                    <label style={{ fontSize: '13px' }}>Enter Skill Separated Comma(,)</label>
                                                    <br />
                                                    {/* <input type="text" className='form-control' name="skills" onChange={(e) => setSkills(e.target.value.length === 0 ? null : e.target.value)} /> */}
                                                    <ReactSearchAutocomplete
                                                        styling={{
                                                            zIndex: 99,
                                                            borderRadius: 0
                                                        }}
                                                        items={items}
                                                        onSearch={handleOnSearch}
                                                        onHover={handleOnHover}
                                                        onSelect={handleOnSelect}
                                                        onFocus={handleOnFocus}
                                                        autoFocus
                                                        formatResult={formatResult}
                                                    />
                                                    <br />
                                                    <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                </div>
                                                <div className='col-lg-2 col-sm-12'>
                                                    <label style={{ fontSize: '13px' }}>Title</label>
                                                    <br />
                                                    <input type="text" className='form-control' name="jobTitle" onChange={(e) => setJobTitle(e.target.value.length === 0 ? null : e.target.value)} />
                                                    <br />
                                                    {/* <label style={{ fontSize: '13px', color: 'red' }}>{message}</label> */}
                                                </div>
                                                <div className='col-lg-1 col-sm-12'>
                                                    <label style={{ fontSize: '13px' }}>City</label>
                                                    <br />
                                                    <input type="text" className='form-control' name="rankLocation" onChange={(e) => setRankLocation(e.target.value.length === 0 ? null : e.target.value)} />
                                                    <br />
                                                    {/* <label style={{ fontSize: '13px', color: 'red' }}>{message}</label> */}
                                                </div>
                                                <div className='col-lg-1 col-sm-12'>
                                                    <label style={{ fontSize: '13px' }}>State</label>
                                                    <br />
                                                    <input type="text" className='form-control' name="rankLocation" onChange={(e) => setRankState(e.target.value.length === 0 ? null : e.target.value)} />
                                                    <br />
                                                    {/* <label style={{ fontSize: '13px', color: 'red' }}>{message}</label> */}
                                                </div>
                                                <div className='col-lg-1 col-sm-12'>
                                                    <label style={{ fontSize: '13px' }}>Country</label>
                                                    <br />
                                                    <input type="text" className='form-control' name="country" onChange={(e) => setRankCountry(e.target.value.length === 0 ? null : e.target.value)} />
                                                    <br />
                                                    {/* <label style={{ fontSize: '13px', color: 'red' }}>{message}</label> */}
                                                </div>
                                                <div className='col-lg-1 col-sm-6'>
                                                    <label style={{ fontSize: '13px' }}>Count</label>
                                                    <br />
                                                    <select className='form-control' name="resumeCount" onChange={(e) => setResumeCount(e.target.value)} >
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                        <option value={200}>200</option>
                                                        <option value="all">All</option>
                                                    </select>
                                                </div>
                                                <div className='col-1' style={{ textAlign: 'left' }}>
                                                    <button type='button'
                                                        onClick={() =>
                                                            searchRank()
                                                        }
                                                        style={{
                                                            marginTop: '33px',
                                                        }} className="btn btn-outline-dark btn-sm" >Search</button>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <label style={{ fontSize: '12px' }}>Recent Searches: {batchSearchData.map(x => {
                                                return <button className="mr-1" style={{
                                                    textDecoration: 'underline',
                                                    backgroundColor: 'transparent',
                                                    backgroundRepeat: 'no-repeat',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    overflow: 'hidden',
                                                    outline: 'none'
                                                }}
                                                    onClick={() => handleSetBatchData(x.search_key)}
                                                >{x.search_key} {" "}</button>
                                            })}</label>

                                        </div> */}
                                        <div>
                                            <div>
                                                <LoadingOverlay
                                                    active={loader}
                                                    spinner
                                                    text=''
                                                >
                                                    {
                                                        <div className="" style={{ overflow: 'auto' }}>
                                                            <div className="col-auto">
                                                                <DataTable
                                                                    columns={columns}
                                                                    data={filteredItems}
                                                                    progressPending={loading}
                                                                    pagination
                                                                    paginationServer
                                                                    paginationTotalRows={totalRows}
                                                                    // onChangeRowsPerPage={handlePerRowsChange}
                                                                    // onChangePage={handlePageChange}
                                                                    subHeader
                                                                    subHeaderComponent={subHeaderComponent}
                                                                    paginationComponentOptions={paginationComponentOptions}
                                                                />

                                                            </div>
                                                        </div>
                                                    }

                                                </LoadingOverlay>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    advanceSearch &&
                                    <>
                                        <div className="container" style={{ overflow: 'auto' }}>
                                            <div className='row'>
                                                <input type="radio" checked={searchOption?.basicSearch} style={{ marginTop: '37px', marginRight: '17px' }} onClick={(e) => setSearchOption({
                                                    simpleRankSearch: false,
                                                    rankLocationSearch: false,
                                                    basicSearch: true,
                                                    orSearch: false,
                                                    andSearch: false
                                                })} />
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <label style={{ fontSize: '13px' }}>Select Option</label>
                                                        <select
                                                            disabled={searchOption?.orSearch || searchOption?.andSearch}
                                                            className='form-control form-control-sm' name="skill_option" onChange={(e) => {
                                                                setSkillOption(e.target.value)
                                                                // setRankResult([])
                                                                // setAdvanceResultData([])
                                                            }} required >
                                                            <option value="Full Name">Full Name</option>
                                                            <option value="First Name">First Name</option>
                                                            <option value="Last Name">Last Name</option>
                                                            <option value="Email">Email</option>
                                                            {/* <option value="Resume Key Skills">Resume Key Skills</option>
                                                            <option value="Resume Keyword">Resume Keyword</option> */}
                                                            <option value="Primary Skills">Primary Skills</option>
                                                            <option value="Key Skills">Key Skills</option>
                                                            <option value="City/State">City/State</option>
                                                            <option value="Country">Country</option>
                                                            <option value="Immigration Status">Immigration Status</option>
                                                            <option value="Phone Number">Phone Number</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-6'>
                                                        <label style={{ fontSize: '13px' }}>{skillOption}</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.orSearch || searchOption?.andSearch}
                                                            type="search" placeholder={skillOption} className='form-control form-control-sm' name="skills" onChange={(e) => {
                                                                setOptionValue(e.target.value)
                                                            }} required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    <div className=''>
                                                        <button type='button '
                                                            disabled={searchOption?.orSearch || searchOption?.andSearch}
                                                            onClick={() => handleAdvanceSearch()}
                                                            style={{
                                                                marginTop: '30px',
                                                            }} className="btn btn-outline-dark btn-sm" >Search</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <input type="radio" checked={searchOption?.orSearch} style={{ marginTop: '37px', marginRight: '17px' }} onClick={(e) => setSearchOption({
                                                    simpleRankSearch: false,
                                                    rankLocationSearch: false,
                                                    basicSearch: false,
                                                    orSearch: true,
                                                    andSearch: false
                                                })} />
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label style={{ fontSize: '13px' }}>Key Skills</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.basicSearch || searchOption?.andSearch}
                                                            type="search" placeholder='Key Skills' className='form-control form-control-sm' name="skills" onChange={(e) => {
                                                                if (e.target.value.length > 0) {
                                                                    setOrSkills(e.target.value)
                                                                } else {
                                                                    setOrSkills(null)
                                                                }
                                                            }} required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                    <label style={{ marginTop: '35px', fontSize: '13px', marginLeft: '-14px' }}>OR</label>
                                                    <div className='col-4'>
                                                        <label style={{ fontSize: '13px' }}>Location</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.basicSearch || searchOption?.andSearch}
                                                            type="search" placeholder='Location' className='form-control form-control-sm' name="location" onChange={(e) => {
                                                                if (e.target.value.length > 0) {
                                                                    setOrLocation(e.target.value)
                                                                } else {
                                                                    setOrLocation(null)
                                                                }

                                                            }} required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                    <label style={{ marginTop: '35px', fontSize: '13px', marginLeft: '-14px' }}>OR</label>
                                                    <div className='col-3'>
                                                        <label style={{ fontSize: '13px' }}>Immigration Status</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.basicSearch || searchOption?.andSearch}
                                                            type="search" placeholder='Immigration Status' className='form-control form-control-sm' name="workAuthorization" onChange={(e) => {
                                                                if (e.target.value.length > 0) {
                                                                    setOrWorkAuthroization(e.target.value)
                                                                } else {
                                                                    setOrWorkAuthroization(null)
                                                                }

                                                            }
                                                            } required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                </div>
                                                <div className='col-2' style={{ textAlign: 'left' }}>
                                                    <div className='s-b'>
                                                        <button type='button'
                                                            disabled={searchOption?.basicSearch || searchOption?.andSearch}
                                                            onClick={() => handleOrSearch()}
                                                            style={{
                                                                marginTop: '30px',
                                                            }} className="btn btn-outline-dark btn-sm " >Search</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <input type="radio" checked={searchOption?.andSearch} style={{ marginTop: '37px', marginRight: '17px' }} onClick={(e) => setSearchOption({
                                                    simpleRankSearch: false,
                                                    rankLocationSearch: false,
                                                    basicSearch: false,
                                                    orSearch: false,
                                                    andSearch: true
                                                })} />
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label style={{ fontSize: '13px' }}>Key Skills</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.basicSearch || searchOption?.orSearch}
                                                            type="search" placeholder='Key Skills' className='form-control form-control-sm' name="skills" onChange={(e) => {
                                                                if (e.target.value.length > 0) {
                                                                    setAndSkills(e.target.value)
                                                                } else {
                                                                    setAndSkills(null)
                                                                }
                                                            }} required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                    <label style={{ marginTop: '35px', fontSize: '13px', marginLeft: '-15px' }}>AND</label>
                                                    <div className='col-4' style={{ marginLeft: '-9px' }}>
                                                        <label style={{ fontSize: '13px' }}>Location</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.basicSearch || searchOption?.orSearch}
                                                            type="search" placeholder='Location' className='form-control form-control-sm' name="location" onChange={(e) => {
                                                                if (e.target.value.length > 0) {
                                                                    setAndLocation(e.target.value)
                                                                } else {
                                                                    setAndLocation(null)
                                                                }
                                                            }} required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                    <label style={{ marginTop: '35px', fontSize: '13px', marginLeft: '-15px' }}>AND</label>
                                                    <div className='col-3' style={{ marginLeft: '-5px' }}>
                                                        <label style={{ fontSize: '13px' }}>Immigration Status</label>
                                                        <br />
                                                        <input
                                                            disabled={searchOption?.basicSearch || searchOption?.orSearch}
                                                            type="search" placeholder='Immigration Status' className='form-control form-control-sm' name="workAuthorization" onChange={(e) => {
                                                                if (e.target.value.length > 0) {

                                                                    setAndWorkAuthroization(e.target.value)
                                                                } else {
                                                                    setAndWorkAuthroization(null)
                                                                }
                                                            }} required />
                                                        <br />
                                                        <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                                    </div>
                                                </div>
                                                <div className='col-2 ncofs' style={{ textAlign: 'left' }}>
                                                    <div className='s-b'>
                                                        <button type='button'
                                                            disabled={searchOption?.basicSearch || searchOption?.orSearch}
                                                            onClick={() => handleAndSearch()}
                                                            style={{
                                                                marginTop: '30px',
                                                            }} className="btn btn-outline-dark btn-sm " >Search</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <LoadingOverlay
                                                    active={loader}
                                                    spinner
                                                    text=''
                                                >
                                                    <div className="" style={{ overflow: 'auto' }}>
                                                        <div className='col-4' style={{ margin: '10px', left: '65%' }}>
                                                            <input className='form-control' type="text" onChange={(e) => handleAdvanceSearchFilter(e)} placeholder="Search" />
                                                        </div>
                                                        <div className="col-auto">
                                                            <table id="" className="table table-hover table-sm rwd-table" style={{ width: '100%', fontSize: '13px' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>List</th>
                                                                        <th>Pipeline</th>
                                                                        <th>Priority</th>
                                                                        <th>First Name</th>
                                                                        <th>Last Name</th>
                                                                        <th>Email</th>
                                                                        <th>Mobile</th>
                                                                        <th>Address</th>
                                                                        <th>City</th>
                                                                        <th>State</th>
                                                                        <th>Country</th>
                                                                        <th>Immigration Status</th>
                                                                        <th>Primary Skills</th>
                                                                        <th>Owner</th>
                                                                        <th>Created Date</th>
                                                                        <th>Key Skills</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        advanceResultData?.map((x, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td data-th="List">
                                                                                        {
                                                                                            <label title="Add to List" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                                                addToList(x, index)
                                                                                            }}>
                                                                                                <i class="bi bi-card-list"></i>
                                                                                            </label>
                                                                                        }
                                                                                    </td>
                                                                                    <td data-th="Pipeline" title="Add to Joborder Pipeline" onClick={() => handleCandidatePipeLine(x?.result1)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Add</td>
                                                                                    <td data-th="">
                                                                                        {x.result1.priority}
                                                                                    </td>
                                                                                    <td data-th="First Name">
                                                                                        <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                                            openModal(x)
                                                                                            setOperationMode('advanceSearch')
                                                                                        }}>
                                                                                            {x?.result1?.first_name}
                                                                                        </label>
                                                                                    </td>
                                                                                    <td data-th="Last Name">
                                                                                        <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                                            openModal(x)
                                                                                            setOperationMode('advanceSearch')
                                                                                        }}>
                                                                                            {x?.result1?.last_name}
                                                                                        </label>
                                                                                    </td>
                                                                                    <td data-th="Email" style={{ wordWrap: 'break-word' }}>
                                                                                        {x?.result1?.email1}
                                                                                    </td>
                                                                                    <td data-th="Mobile" style={{ wordWrap: 'break-word' }}>
                                                                                        {x?.result1?.phone_home} {x?.result1?.phone_work} {x?.result1?.phone_cell}
                                                                                    </td>
                                                                                    <td data-th="Address">
                                                                                        {x?.result1?.address}
                                                                                    </td>
                                                                                    <td data-th="City">
                                                                                        {x?.result1?.city}
                                                                                    </td>
                                                                                    <td data-th="State">
                                                                                        {x?.result1?.state}
                                                                                    </td>
                                                                                    <td data-th="Country">
                                                                                        {x?.result1?.country}
                                                                                    </td>
                                                                                    <td data-th="Immigration Status">{x?.result1?.immigration_status}</td>
                                                                                    <td data-th="Primary Skills">
                                                                                        {x?.result1?.primary_skills}
                                                                                    </td>
                                                                                    <td data-th="Owner">
                                                                                        {x?.result1?.owner}
                                                                                    </td>
                                                                                    <td data-th="Created Date">{moment(x?.result1?.date_created).format("MM-DD-YYYY, h:mm:ss")}</td>
                                                                                    <td data-th="Key Skills">{x?.result1?.key_skills}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </LoadingOverlay>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        )
                    }
                    <Modal
                        isOpen={jobOrderModal}
                        onRequestClose={() => setJobOrderModal(false)}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label><b>Add Candidate to Job Order Pipeline</b></label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => setJobOrderModal(false)}>close</button>
                        <div>
                            <div className='col-lg-3 col-sm-5'>
                                <label>Joborder Status</label>
                                <select value={filterType} onChange={(e) => setFilterType(e.target.value)} className='form-control mb-2'>
                                    <option value="">Select One</option>
                                    <option value="Active">Active</option>
                                    <option value="Upcoming">Upcoming</option>
                                    <option value="Lead">Prospective / Lead</option>
                                    <option value="OnHold">On Hold</option>
                                    <option value="Full">Full</option>
                                    <option value="Closed">Closed</option>
                                    <option value="Canceled">Canceled</option>
                                    <option value="Internal">Internal</option>
                                    <option value="All">All</option>
                                </select>
                            </div>
                            {
                                <div>
                                    {
                                        jobOrderLoader && <center>Loading....</center>

                                    }{jobOrderLoader === false &&
                                        <div>
                                            <table id="jobOrderTable" className="table table-hover table-sm rwd-table" style={{ width: '100%', overflow: 'auto', padding: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ display: 'none' }}></th>
                                                        <th>ID</th>
                                                        <th>Cpy Job ID</th>
                                                        <th>Title</th>
                                                        <th>Company</th>
                                                        <th>Status</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>Recruiter</th>
                                                        <th>Created Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        jobOrderData?.map(x => {
                                                            return (
                                                                <tr key={x?.joborder_id}>
                                                                    <td data-th="" style={{ display: 'none' }}>{x?.joborder_id}</td>
                                                                    <td data-th="ID" >{`NOVIJP00${x?.joborder_id}`}</td>
                                                                    <td data-th="Cpy Job ID"  >{x?.joborder_id}</td>
                                                                    <td data-th="Title" onClick={() => loader ? console.log("Clicked") : createJobOrder(x?.joborder_id)}

                                                                        style={{ color: x?.is_hot === 1 ? "red" : "black", textDecoration: 'underline', cursor: 'pointer' }}>{x?.title}</td>
                                                                    <td data-th="Company" >{x?.companyName}</td>
                                                                    <td data-th="Status" >{x?.status}</td>
                                                                    {/* <td><SubmissionCount id={x.joborder_id} /></td> */}
                                                                    {/* <td><PipeLineCount id={x.joborder_id} /></td> */}
                                                                    <td data-th="City" >{x?.city}</td>
                                                                    <td data-th="State" >{x?.state}</td>
                                                                    {/* <td><PayRate id={x.joborder_id} /></td> */}
                                                                    <td data-th="Recruiter" >{`${x?.createdFirstName} .${x?.createdLastName}`}</td>
                                                                    <td data-th="Created Date" >{moment(x?.date_created).format("MM-DD-YYYY, h:mm:ss")}</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                </div>
                            }
                        </div>
                    </Modal>
                    <Modal
                        isOpen={closeModalOpen}
                        onRequestClose={() => setCloseModal(false)}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>
                            <center>
                                <label><b>Candidate Added to Job Pipeline</b></label>
                                <br />
                                <button className="btn btn-danger" onClick={() => setCloseModal(false)}>close</button>
                            </center>
                        </div>
                    </Modal>
                </div >
                <Modal
                    isOpen={isFileOpen}
                    onRequestClose={() => {
                        setIsFileOpen(false)
                        setAttachNewFile(false)
                    }}
                    style={customStyles}

                    shouldCloseOnOverlayClick={false}
                >
                    {/* {candidateId} */}
                    {
                        viewType === 'addCandidate' &&
                        <button className="btn btn-info float-left mb-2" onClick={() => setAttachNewFile(true)}>Attach New File</button>
                    }
                    <button className="btn btn-danger float-right mb-2" onClick={() => setIsFileOpen(false)}>close</button>
                    <button className="btn btn-info float-right mb-2 mr-2" onClick={() => refreshModal()}>Refresh</button>
                    {
                        attachNewFile &&

                        <div>
                            <UpdateFileUpload candidate_id={candidateId} attachment_id={attachmentId} handleUpdatedResume={() => {
                                setTimeout(() => {
                                    setIsFileOpen(false)
                                    setAttachNewFile(false)
                                    if (viewType === 'addCandidate') {
                                        getAllCandidates()
                                    }
                                }, 1000)
                            }} />
                        </div>

                    }
                    <div>
                        <iframe frameborder="0" id="gview" key={isFileOpen} title='view' src={fileUrl} style={{ height: '100vh', width: '100%' }}
                            sandbox="allow-scripts allow-same-origin"
                        ></iframe>
                    </div>

                </Modal>
                <Modal
                    isOpen={addListView}
                    onRequestClose={() => setAddListView(false)}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <div style={{ fontSize: '11px' }}>
                        <label><b>Add to Candidate Static Lists</b></label>
                        <br />
                        {
                            listData.length > 0 ?

                                <div>

                                    <table id="listTable" className='table table-hover table-sm rwd-table'>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>savedListID</th>
                                                <th>List </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listData?.map((x, index) => {
                                                    return <tr key={x?.savedListID}>
                                                        <th data-th="" style={{ display: 'none' }}>
                                                            {x?.savedListID}
                                                        </th>
                                                        <td data-th="List">
                                                            {editListData?.savedListID === x?.savedListID ?
                                                                <input type="text" value={editListData.description} onChange={(e) => {
                                                                    setEditListData({
                                                                        ...editListData,
                                                                        description: e.target.value
                                                                    })
                                                                }} />
                                                                :
                                                                <>
                                                                    <input type="checkbox" onClick={(e) => handleListData(e.target.checked, index, x?.savedListID)} /> {x?.description} ({x?.numberEntries})
                                                                </>

                                                            }
                                                        </td>
                                                        <td data-th="Action" style={{ cursor: 'pointer' }}>
                                                            {editListData?.savedListID === x?.savedListID ?
                                                                <>
                                                                    <label className='mr-2' style={{ cursor: 'pointer' }} onClick={() => handleUpdateList()}>Save</label>
                                                                    <label className="mr-2" style={{ cursor: 'pointer' }} onClick={() => setEditListData({ savedListID: 0 })}>Cancel</label>
                                                                </>
                                                                :
                                                                <label onClick={() => setEditListData(x)}>
                                                                    <i class="bi bi-pencil-square"></i> Edit
                                                                </label>
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                "Loading..."

                        }
                        <br />
                        {
                            newListView &&
                            <center>
                                <div className='col-6'>
                                    <input type="text" className='form-control sm' placeholder='Enter List Name' onChange={(e) => setListName(e.target.value)} />
                                </div>
                                <div className='mt-2'>
                                    <button type="button" className="btn btn-success btn-sm mr-2" onClick={() => handleAddList()}>Save</button>
                                    <button type="button" className="btn btn-danger btn-sm mr-2" onClick={() => {
                                        setNewListView(false)
                                        setListName(null)
                                    }}>close</button>
                                </div>

                            </center>
                        }
                        <br />
                        {
                            !newListView &&
                            <center>
                                <button type="button" className="btn btn-info btn-sm mr-2" onClick={() => handleNewList()}>New List</button>
                                <button type="button" className="btn btn-success btn-sm mr-2" onClick={() => handleAddListData()}>Add to Lists</button>
                                <button type="button" className="btn btn-danger btn-sm mr-2" onClick={() => setAddListView(false)}>close</button>
                            </center>
                        }

                    </div>
                </Modal>
            </>
        )
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '99%',
        height: '99%',
        fontSize: '11px'
    },
    overlay: { zIndex: 9999999999 }
};
