import React, { useEffect, useState } from "react";
import { OPEN_CATS_URL } from '../../config';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import $ from 'jquery';
import 'datatables.net';
import { getToken } from "../Common/LocalStorage";

export default function Dashboard(props) {
  const [placements, setPlacements] = useState([]);
  const [recentCalls, setRecentCalls] = useState([]);
  const [barGraphData, setBarGraphData] = useState([]);
  const [importantCandidates, setImportantCandidates] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState('yearly');
  const [loader, setLoader] = useState(false);


  const BarGraph = ({ barGraphData }) => {
    let labels = [];

    if (selectedInterval === 'yearly') {
      labels = barGraphData.map((data) => data.unixdate);
    } else if (selectedInterval === 'monthly') {
      labels = barGraphData.map((data) => moment(data.unixdate).format('MMM'));
    } else if (selectedInterval === 'weekly') {
      labels = barGraphData.map((data) => (`${moment(data.start_of_week).format('M/D')}-${moment(data.end_of_week).format('M/D')}`));
    }

    const submittedData = barGraphData.map((data) => data.submitted);
    const interviewingData = barGraphData.map((data) => data.interviewing);
    const placedData = barGraphData.map((data) => data.placed);
    const data = {
      labels: labels,
      datasets: [{ label: 'Submissions', data: submittedData, backgroundColor: 'rgba(75, 192, 192, 0.4)', borderColor: 'rgb(75, 192, 192)', borderWidth: 1, },
      { label: 'Interviews', data: interviewingData, backgroundColor: 'rgba(192, 75, 192, 0.4)', borderColor: 'rgb(192, 75, 192)', borderWidth: 1, },
      { label: 'Hires', data: placedData, backgroundColor: 'rgba(192, 192, 75, 0.4)', borderColor: 'rgb(192, 192, 75)', borderWidth: 1, },],
    };
    return (
      <div>
        <Bar
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                title: 'No.of employees from each state'
              }
            },
          }}
          height="280px"
          data={data}
        />
      </div>
    );
  };
  useEffect(() => {
    getRecentCalls();
    getPlacements();
    getImportantCandidates();
  }, [props]);

  useEffect(() => {
    getBarGraph();
    // eslint-disable-next-line
  }, [selectedInterval]);

  const getBarGraph = async () => {
    setLoader(true);
    await fetch(`${OPEN_CATS_URL}/dashboardRouter/getBarGraph?type=${selectedInterval}`)
      .then((response) => response.json())
      .then((result) => {
        setBarGraphData(result.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoader(false);
      });
  };
  const getPlacements = async () => {
    setLoader(true);
    await fetch(`${OPEN_CATS_URL}/dashboardRouter/getPlacements?id=${getToken('id')}`)
      .then((response) => response.json())
      .then(result => {
        setPlacements(result.data);
        setLoader(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoader(false);
      })
  };
  const getRecentCalls = async () => {
    setLoader(true);
    await fetch(`${OPEN_CATS_URL}/dashboardRouter/getRecentCalls?id=${getToken('id')}`)
      .then((response) => response.json())
      .then(result => {
        setRecentCalls(result.data);
        setLoader(false)
      })
      .catch(error => {
        console.error('Error:', error);
        setLoader(false);
      })
  };

  const getImportantCandidates = async () => {
    await fetch(`${OPEN_CATS_URL}/dashboardRouter/getImportantCandidates?id=${getToken('id')}`)
      .then((response) => response.json())
      .then(result => {
        setImportantCandidates(result.data);
        setLoader(false)
      })
      .catch(error => {
        console.error('Error:', error);
        setLoader(false);
      })
  };
  useEffect(() => {
    if (placements.length > 0) {
      $(document).ready(function () {
        $('#placementsTable').DataTable({
          order: [[3, 'desc']],
          mark: true,
          destroy: true,
        });
      });
    }
  }, [placements]);
  useEffect(() => {
    if (importantCandidates.length > 0) {
      $(document).ready(function () {
        $('#importantCandidatesTable').DataTable({
          "order": [[6, "desc"]],
          mark: true,
          bDestroy: true,

        });
      });
    }
  }, [importantCandidates]);

  return (
    props?.dashboard && (
      <div className="m-3">
        <div className="row col-sm-8 col-lg-12">
          <div className=" division" style={{ display: "flex", minHeight: "140px", marginBottom: '2%', flexWrap: 'wrap' }}>
            <div className="r-b" style={{ flex: 1, minWidth: '220px', border: "1px solid #ccc", marginRight: "10px", marginTop: '10px', padding: '5px', }}>
              My Recent Calls
              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                </thead>
                <tbody>
                  {recentCalls?.map((x, index) => {
                    return <tr key={index}>
                      <td style={{ marginLeft: "5%" }}>{moment(x.date).format('lll')}</td>
                      <td style={{ marginLeft: "5%" }}>{`${x.firstName} ${x.lastName}`}</td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className="ml-3" style={{ flex: 1, minWidth: '220px', border: "1px solid #ccc", marginRight: "10px", marginTop: '10px', padding: '5px' }}>
              <h6>My Upcoming Calls</h6>
            </div>
            <div className="ml-3 " style={{ flex: 1, minWidth: '190px', border: "1px solid #ccc", marginTop: '10px', padding: '5px', maxWidth: "91%" }}>
              <h6>My Upcoming Events</h6>
            </div>
          </div>
          <div className="row col-md-12 col-lg-12 col-sm n-dash" style={{ marginLeft: '1px' }}>
            <div className="col-md-12 col-lg-7 rdivision" >
              <div class name=''>
                <h5>Recent Hires</h5>
                <table id="placementsTable" className="rwd-table" style={{ border: "0.5px solid #ccc", borderCollapse: "collapse" }}>
                  <thead>
                    <tr style={{ borderBottom: "1px solid #ccc", padding: "5px" }} >
                      <th colSpan={1}> Name </th>
                      <th colSpan={1}> Company </th>
                      <th colSpan={1}> Recruiter </th>
                      <th colSpan={1}> Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    {placements?.map((x, index) => {
                      return <tr key={index}>
                        <td data-th="Name">{`${x.firstName} ${x.lastName}`}</td>
                        <td data-th="Company">{x.companyName}</td>
                        <td data-th="Recruiter">{`${x.userFirstName} ${x.userLastName}`}</td>
                        <td data-th="Date">{x.date}</td>
                      </tr>
                    })} </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 border rounded ">
              <div className="col-md-12">
                <div className="p-1" style={{ height: '360px' }}>
                  <h5>Hiring Overview</h5>
                  {loader ? (
                    <div>Loading...</div>
                  ) : (
                    <BarGraph
                      barGraphData={barGraphData}
                      Interval={selectedInterval}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="center">
                  <div className={`btn btn-sm btn-outline-dark  col-4 m-0 ${selectedInterval === 'yearly' ? 'active' : ''}`} onClick={() => setSelectedInterval('yearly')} >
                    <span className="small">Yearly</span>
                  </div>
                  <div className={`btn btn-sm btn-outline-dark col-4 m-0 ${selectedInterval === 'monthly' ? 'active' : ''}`} onClick={() => setSelectedInterval('monthly')} >
                    <span className="small">Monthly</span>
                  </div>
                  <div className={`btn btn-sm btn-outline-dark  col-4 m-0 ${selectedInterval === 'weekly' ? 'active' : ''}`} onClick={() => setSelectedInterval('weekly')} >
                    <span className="small">Weekly</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-mb-10 div4" style={{ marginBottom: "30px", marginTop: '3%' }}>
            <div className="division1">
              <h6>Important Candidates (Submitted, Interviewing, Offered in Active Job Orders)</h6>
              <table id="importantCandidatesTable" className="rwd-table" style={{ border: "1px solid #ccc", borderCollapse: "collapse", fontSize: "10px" }}>
                <thead>
                  <tr style={{ border: "1px solid black", padding: "10px" }}>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Status</th>
                    <th>Position</th>
                    <th>Company</th>
                    <th>Recruiter</th>
                    <th>Date Modified</th>
                  </tr>
                </thead>
                <tbody>
                  {importantCandidates.map((x, index) => (
                    <tr key={index}>
                      <td data-th="First Name">{x.firstName}</td>
                      <td data-th="Last Name">{x.lastName}</td>
                      <td data-th="Status">{x.status}</td>
                      <td data-th="Position">{x.jobOrderTitle}</td>
                      <td data-th="Company">{x.companyName}</td>
                      <td data-th="Recruiter">{`${x.userFirstName} ${x.userLastName}`}</td>
                      <td data-th="Date Modified">{x.dateModified}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
