import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import Select, { createFilter } from 'react-select';
import { getToken } from '../../storage';
import SingleFileUpload from '../FileUpload/singleFileUpload'
import { FixedSizeList as List } from "react-window";
import { toast } from 'react-toastify';

export default function CreateEvent(props) {
    // eslint-disable-next-line
    const [inputValue, setValue] = useState('');
    // eslint-disable-next-line
    const [selectedValue, setSelectedValue] = useState(null);

    const [data, setData] = useState({
        activityType: 200,
        statusType: "Contacted",
        activityNotes: `Status change: Contacted`,
        statusNotes: `Status change: <span style="color: #ff6c00;">Contacted</span>`,
        isStatusSelected: true
    })
    const [loader, setLoader] = useState(false)
    const [activityData, setActivityData] = useState([])
    const [contacts, setContacts] = useState([])
    const [candidates, setCandidates] = useState([])
    const [selectedContact, setSelectedContact] = useState({})
    const [selectedCandidate, setSelectedCandidate] = useState({})
    const [message, setMessage] = useState(null)
    const [createContact, setCreateContact] = useState(false)
    const [createCandidate, setCreateCandidate] = useState(false)
    const [insertId, setInsertId] = useState()
    const [companyData, setCompnayData] = useState([])
    const [selectedCompany, setSelectedCompany] = useState({})
    const [createCompany, setCreateCompany] = useState(false)
    const [jobOrder, setJobOrder] = useState([])
    const [jobOrderId, setJobOrderId] = useState({})
    const [isInvalidResume, setIsInvalidResume] = useState(false)
    const [jobOrderStatus, setJobOrderStatus] = useState([])
    //eslint-disable-next-line
    const [orderStatus, setOrderStatus] = useState({})
    const [isOrder, setIsOrder] = useState(false)
    const [statusData, setStatusData] = useState([])
    const [loadOptionsData, setLoadOptionsData] = useState([])
    const [skillOption, setSkillOption] = useState('Full Name')
    const [optionValue, setOptionValue] = useState(null)

    useEffect(() => {
        if (Object.keys(selectedCandidate).length !== 0) {
            getJobOrder()
        }
        //eslint-disable-next-line
    }, [selectedCandidate])

    useEffect(() => {
        if (Object.keys(jobOrderId).length !== 0) {
            getJobOrderStatus()
        }
    }, [jobOrderId])

    const getJobOrder = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobOrder?id=${selectedCandidate.value}`)
            .then((response) => response.json())
            .then((result) => {
                setJobOrder(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getJobOrderStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobOrderStatus`)
            .then((response) => response.json())
            .then((result) => {
                setJobOrderStatus(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        setTimeout(() => {
            if (message !== null) {
                setMessage("")
                window.location.reload()
            }
        }, 5000)
    }, [message])



    const handleChange = (e) => {
        setData({
            ...data, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (props.logEvent) {
            getActivityType()
            getStatusType()
        }
    }, [props.logEvent])

    useEffect(() => {
        if (data?.regarding === 'contact') {
            getContacts()
            // getCompanyNames()
            setCreateCandidate(false)
        }
        if (data?.regarding === 'candidate' && data?.activityType === undefined) {
            if (props.access.access_level !== 500 || props.access.access_level !== 400) {
                getCandidates()
            }
            setCreateContact(false)
        }
        // eslint-disable-next-line
    }, [data])



    useEffect(() => {
        if (selectedContact?.company_id !== undefined) {
            getCompanyNames(selectedContact?.company_id)
        }
    }, [selectedContact])

    const getCompanyNames = async (company_id) => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCompanyNames?company_id=${company_id}`)
            .then((response) => response.json())
            .then((result) => {
                setCompnayData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getActivityType = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getActivityType`)
            .then((response) => response.json())
            .then((result) => {
                setActivityData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getStatusType = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getStatusType`)
            .then((response) => response.json())
            .then((result) => {
                setStatusData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getCandidates = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidates?id=${getToken('id')}&access=${props.access.access_level}`)
            .then((response) => response.json())
            .then((result) => {
                setCandidates(result.data)
                setContacts([])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getContacts = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getContacts?id=${getToken('id')}`)
            .then((response) => response.json())
            .then((result) => {
                setContacts(result.data)
                setCandidates([])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    var contactsData = contacts.map(x => {
        return {
            value: x.contact_id, label: `${x.first_name + x.last_name} (${x.company_name})`, company_id: x.company_id
        }
    })

    var candidatesData = candidates.map(x => {
        return {
            value: x.candidate_id, label: `${x.first_name + x.last_name}`
        }
    })

    var companyTableData = companyData.map(x => {
        return {
            value: x.company_id, label: x.name
        }
    })
    //eslint-disable-next-line
    var jobOrderData = jobOrder.map(x => {
        return {
            value: x.joborder_id, label: x.title
        }
    })
    //eslint-disable-next-line
    var orderStatusData = jobOrderStatus.map(x => {
        return {
            value: x.candidate_joborder_status_id, label: x.short_description
        }
    })

    function appendObjTo(thatArray, newObj) {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    var newContactsData = contacts.length > 0 ? appendObjTo(contactsData, { value: 'newContact', label: 'Add New Contact' }) : [{ value: 'newContact', label: 'Add New Contact' }]

    var newCandidatesData = candidates.length > 0 ? appendObjTo(candidatesData, { value: 'newCandidate', label: 'Add New Candidate' }) : [{ value: 'newCandidate', label: 'Add New Candidate' }]


    function reverseArr(input) {
        //eslint-disable-next-line
        var ret = new Array;
        for (var i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    const handleSelect = (selectedOption, type) => {
        if (type === "contact") {
            if (selectedOption.value === 'newContact') {
                setCreateContact(true)
                setSelectedContact(selectedOption)
                setSelectedCandidate({})
                setCreateCandidate(false)
                setCreateCompany(false)


            } else {
                setSelectedContact(selectedOption)
                setSelectedCandidate({})
                setCreateCompany(false)
                setCreateContact(false)

            }
        }
        if (type === "candidate") {
            if (selectedOption.value === 'newCandidate') {
                setCreateCandidate(true)
                setSelectedCandidate(selectedOption)
                setSelectedContact({})
                setCreateContact(false)
                setCreateCompany(false)
            } else {
                setSelectedCandidate(selectedOption)
                setSelectedContact({})
                setCreateCompany(false)
                setCreateCandidate(false)

            }
        }
        if (type === 'company') {
            setSelectedCompany(selectedOption)
            setCreateCompany(true)
        }

        if (type === 'joborder') {
            setJobOrderId(selectedOption)
        }
        if (type === 'orderStatus') {
            setOrderStatus(selectedOption)
            setData({ ...data, activityNotes: `Status Change: ${selectedOption.label}`, orderStatus: selectedOption })
            setIsOrder(true)
        }


    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/createEvent`,
            {
                method: 'POST',
                body: JSON.stringify({ data: data, userData: selectedCandidate || selectedContact, created_id: getToken('id'), entered_by: data.regarding === 'contact' && createContact === false ? selectedContact.value : insertId, companyData: selectedCompany, createCompany: createCompany, jobOrderId: jobOrderId || -1, isOrder: isOrder }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setMessage(result.message)
                props.getMessage(result.message)
                props.setEvent()
                setData({})
                setCreateCompany(false)
                setJobOrderId({})
                setIsOrder(false)
            })
            .catch((error) => {
                setMessage("Failed Created Event Log")
                setLoader(false)
            });
        setLoader(false)
    }

    const handleChangeStatus = (e) => {
        var data1 = `Status change: ${e.target.value}`
        setData({
            ...data,
            statusType: e.target.value,
            activityNotes: data1,
            statusNotes: `Status change: <span style="color: #ff6c00;">${e.target.value}</span>`,
            isStatusSelected: true
        })
    }

    const handleChangeNotes = (e) => {

        setData({
            ...data,
            activityNotes: e.target.value,
            statusNotes: e.target.value
        })
    }

    // eslint-disable-next-line
    const handleInputChange = value => {
        setValue(value);
    };

    // handle selection
    const handleOptionChange = value => {
        setSelectedValue(value);
        handleSelect(value, 'candidate')
    }


    const MenuList = function MenuList(props) {
        const { options, children, maxHeight, getValue } = props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * 35;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={35}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    };

    const handleCandidateSearch = async () => {
        setLoader(true)
        if (optionValue !== null) {
            await fetch(
                `${OPEN_CATS_URL}/searchRouter/advanceSearch`, {
                method: 'POST',
                body: JSON.stringify({
                    "option_value": optionValue,
                    "cats_user": props.access,
                    "skill_option": skillOption
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    setLoadOptionsData(result.data.map(x => {
                        return {
                            value: x.result1.candidate_id, label: `${x.result1.first_name + x.result1.last_name}`, data: x.result1
                        }
                    }))
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            toast.warn("Please Enter Search Value", {
                hideProgressBar: true
            })
        }
        setLoader(false)
    }


    return (
        <div>
            <div className="container mt-3">
                {
                    props.logEvent && (
                        <form className="form-group col-12 log-event" onSubmit={(e) => handleSubmit(e)}>
                            <div className="form-group">
                                <label>Regarding*</label>
                                <select name="regarding" className="form-control form-control-sm" onChange={(e) => handleChange(e)} required>
                                    <option value="">Select One</option>
                                    <option value="candidate">Candidate</option>
                                    <option value="contact">Contact</option>
                                </select>
                            </div>
                            {data.regarding === 'candidate' && newCandidatesData.length > 0 && (
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className="nlf" style={{ fontSize: '13px' }}>Select Option</label>
                                            <select
                                                className='form-control form-control-sm nlfr' name="skill_option" onChange={(e) => {
                                                    setSkillOption(e.target.value)
                                                }} required >
                                                <option value="Full Name">Full Name</option>
                                                <option value="First Name">First Name</option>
                                                <option value="Last Name">Last Name</option>
                                                <option value="Email">Email</option>
                                                <option value="Primary Skills">Primary Skills</option>
                                                <option value="Key Skills">Key Skills</option>
                                                <option value="City/State">City/State</option>
                                                <option value="Country">Country</option>
                                                <option value="Immigration Status">Immigration Status</option>
                                                <option value="Phone Number">Phone Number</option>
                                            </select>
                                        </div>
                                        <div className='col-3'>
                                            <label className="nlf s-lpt" style={{ fontSize: '13px' }}>{skillOption}</label>
                                            <br />
                                            <input
                                                type="search" placeholder={`Enter ${skillOption}`} className='form-control form-control-sm nlfr s-lpt' name="skills" onChange={(e) => {
                                                    setOptionValue(e.target.value)
                                                }} required />
                                            <br />
                                            <label style={{ fontSize: '13px', color: 'red' }}>{message}</label>
                                        </div>
                                        <div className='col-2'>
                                            <div>
                                                <button type='button'
                                                    onClick={() => handleCandidateSearch()}
                                                    style={{
                                                        marginTop: '27px',
                                                    }}
                                                    className="btn btn-outline-dark btn-sm sopt" >{loader ? "Loading..." : "Search"}</button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Select Candidate*</label>
                                        {
                                            <Select
                                                value={selectedCandidate}
                                                onChange={handleOptionChange}
                                                options={reverseArr(loadOptionsData)}
                                            />
                                        }
                                    </div>
                                </>
                            )}
                            {data.regarding === 'contact' && newContactsData.length > 0 && (
                                <div className="form-group">
                                    <label>Select Contact*</label>
                                    <Select
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        components={{ MenuList }}
                                        onChange={(selectedOption) => handleSelect(selectedOption, 'contact')}
                                        options={reverseArr(newContactsData)} />
                                </div>

                            )}
                            {
                                jobOrder.length > 0 && (
                                    <div className="form-group">
                                        <label>Select Job Order</label>
                                        <Select
                                            // value={selectedContact}
                                            onChange={(selectedOption) => handleSelect(selectedOption, 'joborder')}
                                            options={jobOrderData} />
                                    </div>
                                )
                            }
                            {/* {
                                jobOrderStatus.length > 0 && (
                                    <div className="form-group">
                                        <label>Select Job Order Status*</label>
                                        <Select
                                            // value={selectedContact}
                                            onChange={(selectedOption) => handleSelect(selectedOption, 'orderStatus')}
                                            options={orderStatusData} />
                                    </div>
                                )
                            } */}
                            {
                                createCandidate && (
                                    <SingleFileUpload invalidResume={(value) => setIsInvalidResume(value)} setInsertID={(id) => setInsertId(id)} type="activityData" />
                                )
                            }
                            {
                                createContact && (
                                    <div className="row col-12">
                                        <div className="form-group  col-6">
                                            <label>First Name*</label>
                                            <input type="text" name="firstName" onChange={(e => handleChange(e))} className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group  col-6">
                                            <label>Last Name*</label>
                                            <input formEncType="text" name="lastName" onChange={(e => handleChange(e))} className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group  col-12">
                                            <label>Company*</label>
                                            <Select
                                                // value={selectedContact}
                                                onChange={(selectedOption) => handleSelect(selectedOption, 'company')}
                                                options={companyTableData} />
                                        </div>
                                        <div className="form-group  col-6">
                                            <label>Email*</label>
                                            <input type="email" name="email" className="form-control form-control-sm" onChange={(e => handleChange(e))} />
                                        </div>
                                        <div className="form-group  col-6">
                                            <label>Mobile*</label>
                                            <input type="text" name="mobile" className="form-control form-control-sm" onChange={(e => handleChange(e))} />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                selectedCandidate?.data !== undefined &&
                                <div>
                                    < table className='table table-hove table-sm'>
                                        <thead>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>First Name: {selectedCandidate?.data?.first_name}</td>
                                                <td>Last Name: {selectedCandidate?.data?.last_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Email: {selectedCandidate?.data?.email1}</td>
                                                <td>Phone: {selectedCandidate?.data?.phone_home}</td>
                                            </tr>
                                            <tr>
                                                <td>Country: {selectedCandidate?.data?.country}</td>
                                                {/* <td>Key Skills: {selectedCandidate?.data?.key_skills}</td> */}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div className="form-group">
                                <label>Activity Type*</label>
                                <select name="activityType" className="form-control form-control-sm" onChange={(e) => handleChange(e)} required>
                                    <option value="">Select One</option>
                                    {
                                        activityData?.map((x, id) => {
                                            return (
                                                <option key={id} selected={x.activity_type_id === 200} value={x.activity_type_id}>{x.short_description}</option>
                                            )
                                        })
                                    }

                                </select>
                            </div>
                            {
                                data.regarding === 'contact' ?
                                    <div className="form-group">
                                        <label>Status Type</label>
                                        <select name="statusType" className="form-control form-control-sm" onChange={(e) => setJobOrderId({ value: e.target.value })}>
                                            <option value="">General</option>
                                            {
                                                companyData?.map((x, id) => {
                                                    return (
                                                        <option key={id} value={x.joborder_id}>{x.title}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                    :
                                    <div className="form-group">
                                        <label>Status Type</label>
                                        <select name="statusType" className="form-control form-control-sm" onChange={(e) => handleChangeStatus(e)}>
                                            <option value="">Select One</option>
                                            {
                                                statusData?.map((x, id) => {
                                                    return (
                                                        <option key={id} selected={x.short_description === "Contacted"} value={x.short_description}>{x.short_description}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>

                            }

                            <div className="form-group">
                                <label>Activity Notes</label>
                                <textarea name="activityNotes" value={data.activityNotes} className="form-control form-control-sm" onChange={(e) => handleChangeNotes(e)}></textarea>
                            </div>
                            <div className="mt-2">
                                {message}
                            </div>
                            {
                                !isInvalidResume ?
                                    <button title="Create Event" type="submit" disabled={loader} className="btn btn-black">{loader ? "Loading..." : "Create"}</button>
                                    :
                                    "Please Select Valid Resume"
                            }

                        </form>
                    )
                }
            </div>
        </div >
    )

}
