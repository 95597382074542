import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import Navbar from '../Common/Navbar'
import { useHistory } from 'react-router-dom'
import SingleFileUpload from '../FileUpload/singleFileUpload'
import moment from 'moment'
import Modal from 'react-modal';
// eslint-disable-next-line
import LoadingOverlay from 'react-loading-overlay';
import { decrypt } from '../Common/crypto'


export default function VendorView() {
    //eslint-disable-next-line
    const history = useHistory()
    //eslint-disable-next-line
    const [jobData, setJobData] = useState([])
    const [candidateView, setCandidateView] = useState(false)
    const [message, setMessage] = useState(null)
    const [payRate, setPayRate] = useState()
    const [immigrationStatus, setImmigrationStatus] = useState()
    const [experienceNeeded, setExperienceNeeded] = useState()
    const [loader, setLoader] = useState(false)
    const [jobEndDate, setJobEndDate] = useState()
    const [vendorData, setVendorData] = useState([])
    const [jobDescriptionView, setJobDescriptionView] = useState(false)
    const [jobViewData, setJobViewData] = useState()
    const [vendorCandidates, setVendorCandidates] = useState([])

    useEffect(() => {
        getJobDetail()
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('job-slug')])

    useEffect(() => {
        getVendorDetail()
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('vendor-slug')])

    useEffect(() => {
        getPayRate()
        getImmigrationStatus()
        getExperienceNeeded()
        getJobEndDate()
    }, [jobData])

    const getJobDetail = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobDetail`, {
            method: 'POST',
            body: JSON.stringify({
                job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobData(result)
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoader(false)
            });
    }

    const getVendorDetail = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/vendorRouter/getSingleVendor`, {
            method: 'POST',
            body: JSON.stringify({
                vendor_id: decrypt(new URLSearchParams(window.location.search)?.get('vendor-slug'))
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setVendorData(result.data)
                getVendorCandidates(result.data)
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoader(false)
            });
    }

    const getVendorCandidates = async (vendorData) => {
        await fetch(
            `${OPEN_CATS_URL}/vendorRouter/getVendorCandidates`, {
            method: 'POST',
            body: JSON.stringify({
                vendor_id: decrypt(new URLSearchParams(window.location.search)?.get('vendor-slug')),
                job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug')),
                data: vendorData
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setVendorCandidates(result.data)
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoader(false)
            });
    }

    const getPayRate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getPayRates`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPayRate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getImmigrationStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getImmigrationStatus`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setImmigrationStatus(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getExperienceNeeded = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getExperienceNeeded`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setExperienceNeeded(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleCandidateView = () => {
        setCandidateView(!candidateView)
        setTimeout(() => {
            document.getElementById("attachResume").scrollIntoView({
                behavior: 'smooth',
            });
        }, 100)

    }
    const getJobEndDate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobEndDate`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobEndDate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleJobDescriptionView = (data) => {
        setJobDescriptionView(true)
        setJobViewData(data)
    }

    return (
        <div>
            <Navbar />
            <div className='container mb-5' style={{ marginTop: '7%', fontSize: '12px' }}>
                {/* <div>
                    <button style={{ cursor: 'pointer' }}
                        type="button"
                        onClick={() => {
                            history.push('/careers')
                        }}
                        className='btn btn-dark btn-sm'>Back</button>
                </div> */}
                {
                    loader ?
                        <center>Loading....</center>
                        :
                        <>
                            {
                                jobData?.length === 0 || vendorData.length === 0 ?
                                    <center>
                                        No Data Found
                                    </center>
                                    :
                                    <div id="job">
                                        {
                                            jobData?.map(x => {
                                                return (
                                                    <div key={x} className='row col-12' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', fontSize: '14px', textAlign: 'left' }}>
                                                        <div className='col-8'>
                                                            <div style={{ padding: 5 }}>
                                                                <div>
                                                                    <label className='mr-2' style={{ color: x.is_hot === 1 ? 'red' : 'black', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                                                                        handleJobDescriptionView(x)
                                                                    }}>{x.title} - {`NOVIJP00${x.joborder_id}`} </label>
                                                                    <label style={{ cursor: 'pointer' }} onClick={() => {
                                                                        handleJobDescriptionView(x)
                                                                    }}>{" "}<i style={{ color: '#5d86ef' }} className="bi bi-box-arrow-up-right"></i></label>
                                                                </div>
                                                                <label className='mr-2'>Location : {x.city} {" "} {x.state} {" "}  {x.country}</label>
                                                                <label className='mr-2'>Duration : {x.duration}</label>
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <div style={{ float: 'right', margin: '20px' }}>
                                                                <button style={{ cursor: 'pointer' }} onClick={() => handleCandidateView()}
                                                                    className='btn btn-dark btn-sm'>Submit Candidate</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                        {
                                            message !== null &&
                                            <Modal
                                                isOpen={true}
                                                onRequestClose={() => window.location.replace('/Careers')}
                                                style={customStyles}
                                                shouldCloseOnOverlayClick={true}
                                            ><>
                                                    <div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px' }}>
                                                        {message}
                                                    </div>
                                                    <div>
                                                        <center>
                                                            <button onClick={() => window.location.reload()} className='btn-sm btn btn-dark btn-sm'>Close</button>
                                                        </center>
                                                    </div>
                                                </>
                                            </Modal>

                                        }
                                        {
                                            candidateView &&
                                            <div id="attachResume" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px' }}>
                                                <label style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                                                    SUBMIT A CANDIDATE'S INFORMATION
                                                </label>
                                                <div className='container' style={{ fontSize: '14px' }}>
                                                    <SingleFileUpload type="Attach Candidate" sub_type="vendor_data" vendor_data={vendorData[0]} jobData={jobData[0]} setMessage={(data) => {
                                                        setCandidateView(false)
                                                        setMessage(data)
                                                    }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                            {
                                vendorCandidates?.length === 0 || vendorCandidates.length === 0 ?
                                    <center>
                                      
                                    </center>
                                    :
                                    <div id="job">
                                        <div className='row col-12' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', fontSize: '14px', textAlign: 'left' }}>
                                            <p>Submitted Candidates</p>
                                            <table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {
                                                        vendorCandidates?.map(x => {
                                                            return (
                                                                <tr key={x}>
                                                                    <td>{x?.first_name}</td>
                                                                    <td>{x?.last_name}</td>
                                                                    <td>{x?.email1}</td>
                                                                    <td>{x?.phone_home}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                        </div>



                                    </div>
                            }
                        </>

                }
                <Modal
                    isOpen={jobDescriptionView}
                    onRequestClose={() => {
                        setJobDescriptionView(false)
                    }}
                    style={customStyles2}
                    shouldCloseOnOverlayClick={true}
                >
                    <div>
                        <div className='mb-5'>
                            <button className="btn btn-danger btn-sm float-right" onClick={() => setJobDescriptionView(false)}>close</button>
                        </div>
                        <div className='row' style={{ margin: '10px', padding: '10px', fontSize: '12px' }}>
                            <div>
                                <div>
                                    <label style={{ color: jobViewData?.is_hot === 1 ? 'red' : 'black', fontSize: '20px', fontWeight: 'bold' }}>{jobViewData?.title} - {`NOVIJP00${jobViewData?.joborder_id}`} </label>
                                </div>
                                <div>
                                    {
                                        jobViewData?.city && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Location:</label> {jobViewData?.city} {" "} {jobViewData.state} {" "}{jobViewData.country}</label>
                                    }
                                </div>
                                <div>
                                    {
                                        jobViewData?.duration && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Duration:</label> {jobViewData?.duration}</label>
                                    }
                                </div>
                                <div>
                                    {
                                        jobViewData?.start_date && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Start Date:</label> {jobViewData?.start_date !== null ? moment(jobViewData?.start_date).format('MM/DD/YYYY') : null}</label>
                                    }
                                </div>
                                <div>
                                    {
                                        jobEndDate?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Submission Deadline:</label> {jobEndDate?.value !== null ? jobEndDate?.value : null}</label>
                                    }
                                </div>

                                <div>
                                    {
                                        payRate?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Pay Rate:</label> {payRate?.value}</label>
                                    }

                                </div>
                                <div>
                                    {
                                        immigrationStatus?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Work Authorization:</label> {immigrationStatus?.value}</label>
                                    }
                                </div>
                                <div>
                                    {
                                        experienceNeeded?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Experience Needed:</label> {experienceNeeded?.value}</label>
                                    }
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: jobViewData?.description }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div style={{ marginLeft: '43%', paddingBottom: '10px', fontSize: '12px', position: 'absolute' }}>
                ©{new Date().getFullYear()} Novisync Inc.All Rights Reserved
            </div>
        </div >
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: '100%',
        // height: '80%'
    },
};

const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '80%',
        padding: '5px'
    },
};