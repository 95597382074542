import React, { useEffect, useRef, useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import Navbar from '../Common/Navbar'
import moment from 'moment'
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decrypt } from '../Common/crypto'

export default function ClientSubmitView() {

    //eslint-disable-next-line
    const [jobData, setJobData] = useState([])
    const [payRate, setPayRate] = useState()
    const [immigrationStatus, setImmigrationStatus] = useState()
    const [experienceNeeded, setExperienceNeeded] = useState()
    const [loader, setLoader] = useState(false)
    const [jobEndDate, setJobEndDate] = useState()
    const [jobDescriptionView, setJobDescriptionView] = useState(false)
    const [jobViewData, setJobViewData] = useState()
    const [candidateSubmittedData, setCandidateSubmittedData] = useState([])
    const [isFileOpen, setIsFileOpen] = useState(false)
    const [url, setUrl] = useState(null)
    const [submissionDataView, setSubmissionDataView] = useState(false)
    const [submissionData, setSubmissionData] = useState(null)
    const [filterType, setFilterType] = useState('submitted')
    const [modalName, setModalName] = useState('')
    const [commentView, setCommentView] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedData, setSelectedData] = useState(null)
    const [comments, setComments] = useState("")
    const [commentsHistory, setCommentsHistory] = useState([])
    const messageContainerRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom when messages change
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
        //eslint-disable-next-line
    }, [commentsHistory]);

    useEffect(() => {
        if (new URLSearchParams(window.location.search)?.get('preview') === 'false') {
            setFilterType(new URLSearchParams(window.location.search)?.get('status'))
        }
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('preview')])

    useEffect(() => {
        getClientSubmitJobDetail()
        getCandidateListSubmitted()
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('job-slug')])

    useEffect(() => {
        getPayRate()
        getImmigrationStatus()
        getExperienceNeeded()
        getJobEndDate()
    }, [jobData])



    const getClientSubmitJobDetail = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getClientSubmitJobDetail`, {
            method: 'POST',
            body: JSON.stringify({
                job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobData(result)
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoader(false)
            });
    }

    const getPayRate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getPayRates`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPayRate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getImmigrationStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getImmigrationStatus`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setImmigrationStatus(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getExperienceNeeded = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getExperienceNeeded`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setExperienceNeeded(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const getJobEndDate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobEndDate`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobEndDate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleJobDescriptionView = (data) => {
        setJobDescriptionView(true)
        setJobViewData(data)
    }

    const getCandidateListSubmitted = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getCandidateListSubmitted?contact_id=${new URLSearchParams(window.location.search)?.get('contact') !== null ? decrypt(new URLSearchParams(window.location.search)?.get('contact')) : ""}`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: decrypt(new URLSearchParams(window.location.search)?.get('job-slug'))
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                if (new URLSearchParams(window.location.search)?.get('submit-slug') !== null) {
                    setCandidateSubmittedData(result.filter(x => {
                        return x.client_submit_id.toString() === decrypt(new URLSearchParams(window.location.search)?.get('submit-slug'))
                    }))
                } else {
                    setCandidateSubmittedData(result)
                    if (result.length > 0) {
                        setFilterType(result[0].status)
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const refreshModal = () => {
        var iframe = document.getElementById('gview');
        //eslint-disable-next-line
        iframe.src = iframe.src;
    }

    const handleUpdateStatus = async (x, status) => {
        setSelectedStatus(status)
        setSelectedData(x)
        getClientSubmissionComments(x.client_submit_id)
    }

    const handleSaveStatus = async (e) => {
        e.preventDefault()
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/updateSubmissionStatus`,
            {
                method: 'POST',
                body: JSON.stringify({
                    submission_history_id: selectedData.submission_history_id,
                    status: selectedStatus,
                    user_data: selectedData,
                    endUrl: window.location.href.replace(/&preview=true/g, ''),
                    comments: comments,
                    entered_by: new URLSearchParams(window.location.search)?.get('preview') === "false" ? "Recruiter" : "Client",
                    client_contact_email: jobData[0].contact_email
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setComments("")
                // toast.success(result.message, { hideProgressBar: true })
                getCandidateListSubmitted()
                getClientSubmissionComments(selectedData.client_submit_id)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const getCounts = (type) => {
        return candidateSubmittedData?.filter(x => {
            return x.status === type
        })?.length
    }

    const getClientSubmissionComments = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getClientSubmissionComments`,
            {
                method: 'POST',
                body: JSON.stringify({
                    client_submit_id: id,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setCommentsHistory(result)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const closeCommentView = () => {
        setCommentView(!commentView)
        setSelectedStatus(null)
        setSelectedData(null)
    }

    return (
        <div>
            <Navbar />
            <ToastContainer />
            <div className='container mb-5' style={{ marginTop: '7%', fontSize: '12px' }}>
                {
                    loader ?
                        <center>Loading....</center>
                        :
                        <div>
                            {
                                jobData?.length === 0 ?
                                    <center>
                                        No Data Found
                                    </center>
                                    :
                                    <center>

                                        <div id="job" >
                                            {
                                                jobData?.map(x => {
                                                    return (
                                                        <div key={x} className='row col-8' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', fontSize: '14px', textAlign: 'left' }}>
                                                            <div className='col-8'>
                                                                <div style={{ padding: 5 }}>
                                                                    <div>
                                                                        <label style={{ color: x.is_hot === 1 ? 'red' : 'black', fontWeight: 'bold' }}>{x.title} - {`NOVIJP00${x.joborder_id}`} </label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div style={{ float: 'right' }}>
                                                                    <button style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            // if (candidateView) {
                                                                            //     window.location.reload()
                                                                            // }
                                                                            handleJobDescriptionView(x)
                                                                        }}
                                                                        className='btn btn-dark btn-sm'>View Job Description</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                new URLSearchParams(window.location.search)?.get('preview') === "false" &&
                                                                <div className='container row'>
                                                                    <div className='col-6'>
                                                                        <label>Client Name: {x.contact_first_name}{" "}{x.contact_last_name}</label>
                                                                        <br />
                                                                        <label>Client Email: {x.contact_email}</label>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <label>Job order Owner: {x.ownerFirstName}{" "}{x.ownerLastName}</label>
                                                                        <br />
                                                                        <label>Job order Recruiter: {x.recruiterFirstName}{" "}{x.recruiterLastName}</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                    )
                                                })
                                            }
                                            {
                                                <div className='row col-8' style={{ textAlign: 'left' }}>
                                                    {
                                                        new URLSearchParams(window.location.search)?.get('preview') === 'true' &&
                                                        <>
                                                            <label className='col-8' style={{ fontSize: '14px', fontWeight: 'bold', padding: 5 }}>{candidateSubmittedData?.length} {candidateSubmittedData?.length === 1 ? 'Applicant' : 'Applicants'}</label>
                                                            <label className='col-4' style={{ fontSize: '14px', fontWeight: 'bold', padding: 5, float: 'right', cursor: 'pointer' }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="bi bi-funnel"></i> Filter Applicants ({candidateSubmittedData?.length})</label>
                                                        </>
                                                    }

                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <label className="dropdown-item" onClick={() => {
                                                            setFilterType('submitted')
                                                        }}>Submitted {getCounts('submitted')}</label>
                                                        <label className="dropdown-item" onClick={() => {
                                                            setFilterType('reviewed')
                                                        }}>Reviewed {getCounts('reviewed')}</label>
                                                        <label className="dropdown-item" onClick={() => {
                                                            setFilterType('shortlisted')
                                                        }}>Shortlisted {getCounts('shortlisted')}</label>
                                                        {/* <label className="dropdown-item" onClick={() => {
                                                            setFilterType('interviewAvailable')
                                                        }}>Available for Interview {getCounts('interviewAvailable')}</label> */}
                                                        {/* <label className="dropdown-item" onClick={() => {
                                                            setFilterType('interviewed')
                                                        }}>Interviewed {getCounts('interviewed')}</label> */}
                                                        <label className="dropdown-item" onClick={() => {
                                                            setFilterType('rejected')
                                                        }}>Rejected {getCounts('rejected')}</label>
                                                        <label className="dropdown-item" onClick={() => {
                                                            setFilterType('offered')
                                                        }}>Offered {getCounts('offered')}</label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                candidateSubmittedData?.filter(x => {
                                                    return x.status === filterType
                                                }).map(x => {
                                                    return (
                                                        <div key={x} className='row col-8' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', textAlign: 'left' }}>
                                                            <div className='col-12'>
                                                                <div style={{ padding: 1 }}>
                                                                    <div>
                                                                        <label style={{ fontWeight: 'bold' }}>{x.first_name} {x.last_name}</label> <span style={{ textTransform: 'capitalize', padding: 5 }} class="badge badge-dark">{x.status}</span>
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: 1 }}>
                                                                    <div>
                                                                        <label style={{ fontWeight: 'bold' }}>{x.email1}</label>
                                                                        <br />
                                                                        <label style={{ fontWeight: 'bold' }}>{x.phone_home}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row col-12 mt-3'>
                                                                <div className='col-3'>
                                                                    <center>
                                                                        <img alt="" src="/images/submission.svg" style={{ width: 35 }} />
                                                                        {" "}
                                                                        <label style={{ cursor: 'pointer', color: '#5d86ef' }} onClick={() => {
                                                                            setSubmissionDataView(true)
                                                                            setSubmissionData(x.submission_data)
                                                                            setModalName("Submission Details")
                                                                        }}>
                                                                            Submission Details
                                                                        </label>
                                                                    </center>
                                                                </div>
                                                                <div className='col-3' >
                                                                    <center>
                                                                        <img alt="" src="/images/cover.svg" style={{ width: 35 }} />
                                                                        {" "}
                                                                        <label style={{ cursor: 'pointer', color: '#5d86ef' }} onClick={() => {
                                                                            if (x.cover_letter_path !== null) {
                                                                                setIsFileOpen(true)
                                                                                setUrl(`https://docs.google.com/gview?url=${x.cover_letter_path}&embedded=true`)
                                                                            } else {
                                                                                setSubmissionDataView(true)
                                                                                setSubmissionData(x.cover_letter_data)
                                                                                setModalName("Cover Letter")

                                                                            }
                                                                        }}>
                                                                            Cover Letter
                                                                        </label>
                                                                    </center>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <center>
                                                                        <img alt="" src="/images/resume.svg" style={{ width: 35 }} />
                                                                        {" "}
                                                                        <label style={{ cursor: 'pointer', color: '#5d86ef' }} onClick={() => {
                                                                            setIsFileOpen(true)
                                                                            setUrl(`https://docs.google.com/gview?url=${x.resume_path}&embedded=true`)
                                                                        }}>
                                                                            Resume
                                                                        </label>
                                                                    </center>
                                                                </div>
                                                                {
                                                                    new URLSearchParams(window.location.search)?.get('preview') === 'true' &&
                                                                    <div className='col-3'>
                                                                        <center>
                                                                            <img alt="" src="/images/chat.svg" style={{ width: 35 }} />
                                                                            {" "}
                                                                            <label style={{ cursor: 'pointer', color: '#5d86ef' }} onClick={() => {
                                                                                setCommentView(!commentView)
                                                                                handleUpdateStatus(x, x.status)
                                                                            }}>
                                                                                Chat
                                                                            </label>
                                                                        </center>
                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </div>
                                    </center>
                            }
                            <Modal
                                isOpen={jobDescriptionView}
                                onRequestClose={() => {
                                    setJobDescriptionView(false)
                                }}
                                style={customStyles}
                                shouldCloseOnOverlayClick={true}
                            >
                                <div>
                                    <div className='mb-5'>
                                        <button className="btn btn-danger btn-sm float-right" onClick={() => setJobDescriptionView(false)}>close</button>
                                    </div>
                                    <div className='row' style={{ margin: '10px', padding: '10px', fontSize: '12px' }}>
                                        <div>
                                            <div>
                                                <label style={{ color: jobViewData?.is_hot === 1 ? 'red' : 'black', fontSize: '20px', fontWeight: 'bold' }}>{jobViewData?.title} - {`NOVIJP00${jobViewData?.joborder_id}`} </label>
                                            </div>
                                            <div>
                                                {
                                                    jobViewData?.city && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Location:</label> {jobViewData?.city}</label>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    jobViewData?.duration && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Duration:</label> {jobViewData?.duration}</label>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    jobViewData?.start_date && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Start Date:</label> {jobViewData?.start_date !== null ? moment(jobViewData?.start_date).format('MM/DD/YYYY') : null}</label>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    jobEndDate?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Submission Deadline:</label> {jobEndDate?.value !== null ? jobEndDate?.value : null}</label>
                                                }
                                            </div>

                                            <div>
                                                {
                                                    payRate?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Pay Rate:</label> {payRate?.value}</label>
                                                }

                                            </div>
                                            <div>
                                                {
                                                    immigrationStatus?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Work Authorization:</label> {immigrationStatus?.value}</label>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    experienceNeeded?.value && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Experience Needed:</label> {experienceNeeded?.value}</label>
                                                }
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: jobViewData?.description }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={isFileOpen}
                                onRequestClose={() => {
                                    setIsFileOpen(false)
                                }}
                                style={customStyles2}

                                shouldCloseOnOverlayClick={false}
                            >

                                <button className="btn btn-danger float-right mb-2" onClick={() => setIsFileOpen(false)}>close</button>
                                <button className="btn btn-info float-right mb-2 mr-2" onClick={() => refreshModal()}>Refresh</button>

                                <div>
                                    <iframe frameborder="0" id="gview" key={isFileOpen} title='view' src={url} style={{ height: '100vh', width: '100%' }}
                                        sandbox="allow-scripts allow-same-origin"
                                    ></iframe>
                                </div>

                            </Modal>
                            <Modal
                                isOpen={submissionDataView}
                                onRequestClose={() => {
                                    setSubmissionDataView(false)
                                }}
                                style={customStyles2}

                                shouldCloseOnOverlayClick={false}
                            >

                                <button className="btn btn-danger float-right mb-2" onClick={() => setSubmissionDataView(false)}>close</button>
                                <div className='mt-1'>
                                    <b>{modalName}</b>

                                    <p style={{ fontSize: '12px', marginTop: '25px', justifyContent: 'center' }}>
                                        <div dangerouslySetInnerHTML={{ __html: submissionData }} />
                                    </p>
                                </div>

                            </Modal>
                            <Modal
                                isOpen={commentView}
                                onRequestClose={() => {
                                    setCommentView(false)
                                }}
                                style={customStyles3}

                                shouldCloseOnOverlayClick={false}
                            >
                                <>
                                    <div>
                                        <p style={{ textTransform: 'capitalize' }}>
                                            Applicant Name: <b>{selectedData?.first_name} {selectedData?.last_name}</b>
                                        </p>
                                        <p style={{ textTransform: 'capitalize' }}>
                                            Select Status: <b><div className='col-12'>
                                                <select
                                                    className='form-control'
                                                    style={{ fontSize: '12px', textTransform: 'capitalize' }}
                                                    value={selectedStatus?.status}
                                                    onChange={(e) => {
                                                        handleUpdateStatus(selectedData, e.target.value)
                                                    }}
                                                >
                                                    <option style={{ textTransform: 'capitalize' }} value={selectedData?.status}>{selectedData?.status}</option>
                                                    <option value="submitted" >Submitted</option>
                                                    <option value="reviewed" >Reviewed</option>
                                                    <option value="shortlisted">Shortlisted</option>
                                                    <option value="interviewed">Interviewed</option>
                                                    <option value="rejected">Rejected</option>
                                                    <option value="offered">Offered</option>
                                                </select>
                                            </div></b>
                                        </p>

                                    </div>
                                    {
                                        // chatView &&
                                        <>
                                            <b>Comments:</b>
                                            <div className='mt-2' style={{ height: 'auto', marginBottom: '10px', border: '1px solid black' }}>
                                                <div ref={messageContainerRef} className="message-container">
                                                    {
                                                        commentsHistory?.map(item => (
                                                            <>
                                                                <div className={`message ${item.entered_by === 'Client' ? 'right-message' : 'left-message'}`}>
                                                                    <label style={{ textTransform: 'capitalize', whiteSpace: 'break-spaces' }}>{item.comments}</label>
                                                                    <br />
                                                                    <label style={{ fontSize: '10px', marginBottom: '0px', textTransform: 'capitalize' }}>Status-{item.status}</label>
                                                                    <br />
                                                                    <label style={{ fontSize: '10px' }}>{item.entered_by === 'Client' ? jobData[0]?.contact_email : item.entered_by}{","}{moment(item.time_stamp).format('lll')}</label>
                                                                </div>

                                                            </>
                                                        ))
                                                    }
                                                </div>
                                                <form onSubmit={(e) => handleSaveStatus(e)}>
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="input-group">
                                                                    <textarea value={comments} className="form-control" placeholder="Type your comment here..." onChange={(e) => setComments(e.target.value)} aria-label="Type your comment here..." rows="1" required></textarea>
                                                                    <div className="input-group-append">
                                                                        <button type='submit' className="btn btn-outline-dark btn-sm" >Send</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    }
                                    <div className='mt-1'>
                                        <center>
                                            <button type="button" className="btn btn-outline-danger btn-sm mb-2" onClick={() => closeCommentView()}>Close</button>
                                        </center>
                                    </div>
                                </>


                            </Modal>
                        </div>

                }
            </div>
            <div style={{ marginLeft: '43%', paddingBottom: '10px', fontSize: '12px', position: 'absolute' }}>
                ©{new Date().getFullYear()} Novisync Inc. All Rights Reserved
            </div>
        </div >
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '80%',
        padding: '5px'
    },
};

const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '73vh'
    },
};

const customStyles3 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80vh'
    },
};
