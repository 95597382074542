import { OPEN_CATS_URL } from "../../../config";
import { getToken, setToken } from "../../../storage";

export const sendErrorEmailAlert = (type, user, error) => {
    if (getToken('emailType') === 'parsingEmail') {
        return
    }
    if (getToken('emailType') === 'rankingEmail') {
        return
    }
    fetch(
        `${OPEN_CATS_URL}/sendErrorEmailAlert`, {
        method: 'POST',
        body: JSON.stringify({
            "type": type,
            "user": user,
            "error": JSON.stringify(error)
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then((response) => response.json())
        .then((result) => {
            let email = `${type}Email`
            setToken('emailType', email)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}