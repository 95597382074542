import React, { useEffect, useState } from 'react';
import './login.css'
import { BUILD_DATE, OPEN_CATS_URL, VERSION } from '../../config';
import { getToken, setToken } from '../../storage';
import { useHistory } from 'react-router-dom'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from 'react-toastify';

export default function Login() {

    const CustomToast = ({ message }) => (
        <div>
            {message}{' '}
            <a href="/" rel="noopener noreferrer">
                Click here
            </a>
        </div>
    );

    const getVersion = (req, res) => {
        fetch(`${OPEN_CATS_URL}/checkVersion`)
            .then((response) => response.json())
            .then((result) => {
                if (result.version !== VERSION) {
                    toast.info(<CustomToast message="New Version Available!" />, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
    }

    useEffect(() => {
        // Function to be executed every 10 minutes
        getVersion()
        let waitTime = 10 * 60 * 1000;
        const intervalId = setInterval(getVersion, waitTime);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (getToken('id') !== null && getToken('login') === "true") {
            history.push('/home?page=dashboard')
        }
        // eslint-disable-next-line
    }, [])

    const history = useHistory()

    const [data, setData] = useState({})
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState()
    const [passwordView, setPasswordView] = useState(false)

    const handleChange = (e) => {
        setData({
            ...data, [e.target.name]: e.target.value
        })
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/authRouter/login`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setToken('login', result.isAuthenticated)
                setToken('id', result.data.user_id)
                setToken('login_email', result.data.email)
                setLoader(false)
                if (result.isAuthenticated) {
                    history.push('/home?page=dashboard')
                }
            })
            .catch((error) => {
                setMessage("Invalid User/Credentials")
                setLoader(false)
            });
        setLoader(false)
    }

    return (

        <div>
            <div className="sidenav">
                <div className="login-main-text">
                    <img alt="NATS" src="/images/main-logo.png" style={{ width: '100%' }} />
                    {/* <img alt="novisync" src="https://novisync.com/img/novisync_new.png" width="100" />
                    <img alt="open-cats" src="https://www.opencats.org/logos/opencats-logo.png" width="200" /> */}
                </div>
            </div>
            <div className="main">
                <div className="col-md-6 col-sm-12">
                    <div className="login-form">
                        <form onSubmit={e => handleLogin(e)}>
                            <div className="form-group">
                                <label>Username*</label>
                                <input type="text" name="username" onChange={e => handleChange(e)} className="form-control" placeholder="username" required />
                            </div>
                            <div className="form-group">
                                <label>Password*</label>
                                <input type={!passwordView ? "password" : "text"} name="password" onChange={e => handleChange(e)} className="form-control" placeholder="Password" required />
                                <span style={{
                                    marginTop: '-7%',
                                    position: 'absolute',
                                    marginLeft: '80%'
                                }}>{!passwordView ? <FaEye onClick={() => setPasswordView(!passwordView)} /> : <FaEyeSlash onClick={() => setPasswordView(!passwordView)} />}</span>

                            </div>
                            <div>
                                {message}
                            </div>
                            <button type="submit" disabled={loader} className="btn btn-black">{loader ? "Loading..." : "Login"}</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <p style={{ fontSize: '10px', margin: '0px' }}>
                    Build Version {VERSION} Date {BUILD_DATE}
                </p>
                <p style={{ fontSize: '10px' }}>
                    © {new Date().getFullYear()} Novisync Inc.All Rights Reserved
                </p>
            </div>
        </div >

    )
}
