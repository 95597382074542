import React from 'react'
import { useEffect, useState } from 'react'
import moment from 'moment'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import FilterComponent from '../Common/FilterComponent';
import { getToken, setToken } from '../../storage';
import Modal from 'react-modal';
import { OPEN_CATS_URL } from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Candidate(props) {
  // eslint-disable-next-line
  const [user, setUser] = useState()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterType, setFilterType] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const [updateActivityModal, setUpdateActivityModal] = useState(false);
  const [activityUpdateData, setActivityUpdateData] = useState({});
  const [loader, setLoader] = useState(false)
  // eslint-disable-next-line
  const [message, setMessage] = useState(null)
  // eslint-disable-next-line
  const [pipeLineInfo, setPipeLineInfo] = useState([])
  // eslint-disable-next-line
  const [activityInfo, setActivityInfo] = useState([])
  const [activityData, setActivityData] = useState([])
  const [candidateData, setCandidateData] = useState([])
  const [updatePipeLineModal, setUpdatePipeLineModal] = useState(false);
  const [updatePipeLineData, setUpdatePipeLineData] = useState({})
  // eslint-disable-next-line
  const [jobPipeLineView, setJobPipeLineView] = useState(false)
  const [pipeLineData, setPipeLineData] = useState({
    status: "yes",
    logActivity: "yes"
  })
  const Status = (props) => {
    const [count, setCount] = useState()

    useEffect(() => {
      getLatestStatus()
      //eslint-disable-next-line
    }, [])

    const getLatestStatus = async (id) => {
      await fetch(
        `${OPEN_CATS_URL}/eventRouter/getLatestStatus`, {
        method: 'POST',
        body: JSON.stringify({ candidateId: props.candidateId, joborderId: props.joborderId }),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => response.json())
        .then((result) => {
          setToken('previousStatusId', result.data[0].candidate_joborder_status_id)
          setCount(result.data[0].short_description)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    return (
      <div>{count || "1.No Contact"}  </div>
    )
  }
  const fetchUsers = async page => {
    setLoading(true);
    const response = await axios.get(`${props.url}&page=${page}&per_page=${perPage}&delay=1`);
    setData(response.data.data.filter(item => item.data_item_type !== 300));
    setFilteredData(response.data.data.filter(
      item =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1 && item.data_item_type !== 300
    ))
    setTotalRows(response.data.pageCount);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await axios.get(`${props.url}&page=${page}&per_page=${newPerPage}&delay=1`);
    setData(response.data.data);
    setPerPage(newPerPage);
    setFilteredData(response.data.data.filter(
      item =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    ))
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users

    if (updateActivityModal) {
      getCandidateStatus();
      getActivityType();
    }


    if (updatePipeLineModal) {
      getCandidatePipeLines(pipeLineData);
      getCandidateStatus(pipeLineData);
      getActivityType(pipeLineData);
    }
    // eslint-disable-next-line
  }, [props.url, updateActivityModal, updatePipeLineModal]);

  const getCandidateStatus = async () => {
    await fetch(
      `${OPEN_CATS_URL}/eventRouter/getJobOrderStatus`)
      .then((response) => response.json())
      .then((result) => {
        setCandidateData(result.data)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const getData = (data) => {
    if (data) {
      return (<p dangerouslySetInnerHTML={{ __html: data.concat(" ") }} />)
    }
  }

  const getActivityType = async () => {
    await fetch(
      `${OPEN_CATS_URL}/eventRouter/getActivityType`)
      .then((response) => response.json())
      .then((result) => {
        setActivityData(result.data)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const getCandidateActivity = async (data) => {
    setActivityInfo([])
    await fetch(
      `${OPEN_CATS_URL}/eventRouter/getCandidateActivity`,
      {
        method: 'POST',
        body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => response.json())
      .then((result) => {
        setActivityInfo(result.data)

      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  const getCandidatePipeLines = async (data) => {
    setPipeLineInfo([])
    await fetch(
      `${OPEN_CATS_URL}/eventRouter/getCandidatePipeLines`,
      {
        method: 'POST',
        body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => response.json())
      .then((result) => {
        setPipeLineInfo(result.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const handleUpdateActivity = (candidate_id, activity_id, regarding, short_description, notes, type) => {
    setActivityUpdateData({
      activity_id: activity_id,
      candidate_id: candidate_id,
      regarding: regarding,
      short_description: short_description,
      notes: notes,
      type: type,
      entered_by: getToken('id')
    });
    setUpdateActivityModal(true);
  };
  const handleNotes = (data) => {
    setActivityUpdateData({
      ...activityUpdateData,
      notes: data
    })
  }
  const handleUpdateJobPipeline = (candidate_id, joborder_id, regarding, status) => {
    setUpdatePipeLineData({
      candidateId: candidate_id, joborderId: joborder_id, regarding: regarding, candidateStatus: status,
    });
    setPipeLineData({
      ...pipeLineData,
      candidateId: candidate_id, joborderId: joborder_id, regarding: regarding, created_id: getToken('id')
    });
    setUpdatePipeLineModal(true);
  };
  const handlePipeLine = (e) => {
    const { name, value } = e.target;
    if (name === "candidateStatus") {
      setPipeLineData({
        ...pipeLineData,
        [name]: value,
      });
    } else if (name === "activityType") {
      setPipeLineData({
        ...pipeLineData,
        [name]: value,
      });
    } else if (name === "probability") {
      setPipeLineData({
        ...pipeLineData,
        probability: value,
      });
    } else if (name === "activityNotes") {
      setPipeLineData({
        ...pipeLineData,
        activityNotes: value,
      });
    } else {
      setPipeLineData({
        ...pipeLineData,
        [name]: value,
      });
    }
  };

  const handleUpdateButtonClick = (row) => {
    if (row.joborder_status_type_description && row.joborder_status_type_description.length === 0) {
      handleUpdateActivity(row.candidate_id, row.activity_id, row.regarding, row.short_description, row.notes, row.type,
      );
    } else {
      handleUpdateJobPipeline(row.candidate_id, row.joborder_id, row.regarding, row.short_description, row.notes, row.type
      );
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoader(true)

    let previousStatus = (getToken('previousStatusId'))

    await fetch(
      `${OPEN_CATS_URL}/eventRouter/updateJobPipeLine`, {
      method: 'POST',
      body: JSON.stringify({ pipeLineData: pipeLineData, previousStatus: previousStatus }),

      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.json())
      .then((result) => {
        setLoader(false)
        getCandidatePipeLines(pipeLineData)
        toast(result.message)
        setUpdatePipeLineModal(false)
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoader(false)
      });
  }
  const handleActivitySubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const requestData = {
      activity_id: activityUpdateData.activity_id,
      entered_by: getToken('id'),
      activityType: parseInt(activityUpdateData.activityType),
      notes: activityUpdateData.notes,
    };
    try {
      const response = await fetch(`${OPEN_CATS_URL}/eventRouter/updateUserActivity`, {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        // eslint-disable-next-line
        const result = await response.json();
        getCandidateActivity(activityUpdateData);
        // toast.success(result.message,{hideProgressBar: true});
        setUpdateActivityModal(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to update activity.", {
          hideProgressBar: true
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred while updating the activity.", {
        hideProgressBar: true
      });
    } finally {
      setLoader(false);
    }
  };

  const handleActivityData = (e) => {
    setActivityUpdateData({
      ...activityUpdateData, [e.target.name]: e.target.value
    })
  }
  const columns = [
    {
      name: "Name",
      selector: row => <label title={`${row.first_name + " " + row.last_name}`}></label>,
      sortable: true,
      // width: "200px",
      cell: row => <span data-label="Name">{`${row.first_name + " " + row.last_name}`}</span>
    },
    {
      name: "Regarding",
      selector: row => <label title={row.regarding}>{row.regarding}</label>,
      sortable: true,
      cell: row => <span data-label="Regarding">{row.regarding}</span>
    },
    {
      name: "Event",
      selector: row => <label title={row.short_description}>{row.short_description}</label>,
      sortable: true,
      // width: "100px",
      cell: row => <span data-label="Event">{row.short_description}</span>
    },
    {
      name: "Activity Status",
      selector: row => <label title={row.joborder_status_type_description[row.joborder_status_type_description.length - 1]?.short_description}></label>,
      sortable: true,
      // width: "150px",
      cell: row => <span data-label="Activity Status">{row.joborder_status_type_description[row.joborder_status_type_description.length - 1]?.short_description}</span>
    },
    {
      name: "Event Notes",
      selector: row =>
        <div title={getData(row.notes)}></div>
      ,
      sortable: true,
      // width: "250px",
      cell: row => <span data-label="Event Notes">{getData(row.notes)}</span>
    },
    {
      name: "Entered By",
      selector: row => (
        row.employer_contact_name !== null
          ? `${row.employer_contact_name} ${row.employer_name !== null ? row.employer_name : ""}`
          : `${row.user_first_name} ${row.user_last_name}`
      ),
      sortable: true,
      // width: "100px",
      cell: row => <span data-label="Entered By">{row.employer_contact_name !== null ? `${row.employer_contact_name} ${row.employer_name !== null ? row.employer_name : ""}` : `${row.user_first_name} ${row.user_last_name}`}</span>
    },
    {
      name: "Created Time",
      selector: row => moment(row.date_created).format("MM-DD-YYYY, h:mm:ss"),
      sortable: true,
      // width: "150px",
      cell: row => <span data-label="Created Time" >{moment(row.date_created).format("MM-DD-YYYY, h:mm:ss")}</span>
    },
    {
      name: "Actions",
      cell: row =>
        <span data-label="Actions" >
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => handleUpdateButtonClick(row)}
          >
            Update
          </button>
        </span>
      ,
      // width: "100px",
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true
    },
    // {
    //     name: "Modified Time",
    //     selector: row => moment(row.date_modified).format("MM-DD-YYYY, h:mm:ss"),
    //     sortable: true,
    //     width: "8%"
    // }
  ]

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: totalRows,
  };

  const handleFilterData = (type) => {
    let filterData
    setFilterType(type)
    if (type === 'clear') {
      setFilteredData(data)
      setFilterType(null)
      return
    }
    if (type === 'pipelines') {
      setFilteredData(data.filter(x => {
        return x.notes === 'Added Candidate to pipeline'
      }))
    }
    if (type === 'submissions') {
      filterData = '3.Submitted'
      setData()
    }
    if (type === 'interviews') {
      filterData = '4.Interviewing'
      setData()
    }
    if (type === 'offered') {
      filterData = '6.Offered'
      setData()
    }
    function setData() {
      return setFilteredData(data.filter(x => {
        return x.joborder_status_type_description[0]?.short_description === filterData
      }))
    }
  }
  return (
    <div>
      <div className='col-lg-6 col-sm-6 row mt-1 mb-1 '>
        <button onClick={() => handleFilterData('pipelines')} className={filterType === 'pipelines' ? 'btn btn-sm btn-outline-dark mr-1 active' : 'btn btn-sm btn-outline-dark mr-1'}>Pipelines</button>
        <button onClick={() => handleFilterData('submissions')} className={filterType === 'submissions' ? 'btn btn-sm btn-outline-dark mr-1 active' : 'btn btn-sm btn-outline-dark mr-1'}>Submissions</button>
        <button onClick={() => handleFilterData('interviews')} className={filterType === 'interviews' ? 'btn btn-sm btn-outline-dark mr-1 active' : 'btn btn-sm btn-outline-dark mr-1'}>Interviews</button>
        <button onClick={() => handleFilterData('offered')} className={filterType === 'offered' ? 'btn btn-sm btn-outline-dark mr-1 active' : 'btn btn-sm btn-outline-dark mr-1'}>Offered</button>
        {
          filterType !== null &&
          <button onClick={() => handleFilterData('clear')} className={'btn btn-sm btn-outline-dark mr-2 ml-4'}>Clear X</button>
        }
      </div>
      <div className="table-container dataTable " style={{ width: '100%', overflow: 'auto' }}>
        <DataTable
          columns={columns}
          data={filteredData}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          subHeader
          subHeaderComponent={subHeaderComponent}
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
      <Modal
        isOpen={updatePipeLineModal}
        onRequestClose={() => setUpdatePipeLineModal(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={false}>
        <label><b>{`${updatePipeLineData?.regarding}'s Log Activity`}</b></label>
        <button className="btn btn-danger float-right mb-2" onClick={() => setUpdatePipeLineModal(false)}>close</button>
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <table className="table table-hover table-sm">
              <tr>
                <td>Regarding:</td>
                <td>{updatePipeLineData.regarding}</td>
              </tr>
              <tr>
                <td>Current Status:</td>
                <td id="previousStatus">{<Status candidateId={updatePipeLineData.candidateId} joborderId={updatePipeLineData.joborderId} />}</td>
              </tr>
              <tr>
                <td>Select Status:</td>
                <td className="col-6">
                  <select
                    name="candidateStatus"
                    onChange={(e) => updatePipeLineModal && handlePipeLine(e)}
                    className="form-control form-control-sm" required >
                    <option value="">Select One</option>
                    {
                      candidateData.map((x, index) => {
                        return (
                          <option key={index} value={x.candidate_joborder_status_id}>{x.short_description}</option>)
                      })}
                  </select>
                </td>
              </tr>
              {updatePipeLineModal && pipeLineData.candidateStatus === "400" ? (
                <tr>
                  <td>Closing probability</td>
                  <td className="col-6"><input defaultValue={0} type="range" name="probability" onChange={(e) => handlePipeLine(e)} required={true} />
                    <label className="ml-2">
                      {pipeLineData?.probability !== "0" ? `${pipeLineData?.probability}%` : "0%"}
                    </label>
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td>Activity Type:</td>
                <td className="col-6">
                  <select
                    name="activityType" onChange={(e) => updatePipeLineModal && handlePipeLine(e)} className="form-control form-control-sm" required >
                    <option value="">Select One</option>
                    {
                      activityData.map((x, index) => {
                        return (
                          <option key={index} value={x.activity_type_id}>{x.short_description}</option>
                        )
                      })
                    }
                  </select>
                </td>
              </tr>
              < tr >
                <td>Activity Notes:</td>
                <td className="col-6">
                  <textarea className="form-control" name="activityNotes" onChange={(e) => handlePipeLine(e)} required></textarea>
                </td>
              </tr>

              {/* } */}
              <tr>
                <td>{
                  message === null ?
                    <>
                      <button type="submit" className="btn btn-sm btn-outline-primary mr-2">{loader ? "Loading..." : "Save"}</button>
                      <button type="button" onClick={() => setUpdatePipeLineModal(false)} className="btn btn-sm btn-outline-danger">Cancel</button>

                    </>
                    :
                    <lable style={{ color: 'green' }}>{message}</lable>
                }
                </td>
              </tr>
            </table>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={updateActivityModal}
        onRequestClose={() => setUpdateActivityModal(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={true}  >
        <label><b>{`${activityUpdateData?.regarding}'s Log Activity`}</b></label>
        <button className="btn btn-danger float-right mb-2" onClick={() => setUpdateActivityModal(false)}>Close</button>
        <div>
          <form onSubmit={(e) => handleActivitySubmit(e)}>
            <table className="table table-hover table-sm">
              <tr>
                <td>Regarding:</td>
                <td>{activityUpdateData?.regarding}</td>
              </tr>
              <tr>
                <td>Activity Type:</td>
                <td className="col-6">
                  <select
                    name="activityType"
                    onChange={(e) => handleActivityData(e)}
                    className="form-control"
                    required
                  >
                    <option value="">Select One</option>
                    {
                      activityData.map((x, index) => {
                        return (
                          <option key={index} value={x.activity_type_id}>{x.short_description}</option>
                        )
                      })
                    }
                  </select>
                </td>
              </tr>
              <tr>
                <td>Activity Notes:</td>
                <td className="col-6">
                  <textarea
                    name="activityNotes"
                    onChange={(e) => handleNotes(e.target.value)}
                    className="form-control"
                    required
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  {message === null ? (
                    <>
                      <button type="submit" className="btn btn-sm btn-outline-primary mr-2">
                        {loader ? "Loading..." : "Save"}
                      </button>
                      <button
                        type="button"
                        onClick={() => setUpdateActivityModal(false)}
                        className="btn btn-sm btn-outline-danger"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <label style={{ color: 'green' }}>{message}</label>
                  )}
                </td>
              </tr>
            </table>
          </form>
        </div>
      </Modal>
    </div>
  )
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '99%',
    height: '99%',
    fontSize: '11px'
  },
  overlay: { zIndex: 9999999999 }
};

